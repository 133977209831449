<script>
import { settingProps } from "./settings.vue";
export default {
  name: "SettingsPreviewGrid",
  components: {},
  inject: ["newRubricSettings", "newRubricData"],
  props: {
    ...settingProps,
    sidebarId: String,
  },
};
</script>

<template>
  <div class="settings-grid">
    <template v-if="grade">
      <b>Grade</b>
      <span>
        {{ newRubricData.grades[newRubricSettings.selectedGradeIdx].label }}
      </span>
    </template>

    <template v-if="intensity">
      <b>Grading intensity</b>
      <span>
        {{
          newRubricData.intensities[newRubricSettings.selectedIntensityIdx]
            .label
        }}
      </span>
    </template>

    <template v-if="language">
      <b>Language</b>
      <span>
        {{
          newRubricData.languages.value[newRubricSettings.selectedLanguageIdx]
            .label
        }}
      </span>
    </template>

    <template v-if="category">
      <b>Category</b>
      <span>
        {{
          newRubricData.categories[newRubricSettings.selectedCategoryIdx].label
        }}
      </span>
    </template>

    <b-button
      variant="outline-info"
      size="sm"
      v-b-toggle="sidebarId || 'new-rubric-settings-sidebar'"
    >
      Edit
    </b-button>
  </div>
</template>

<style scoped lang="scss">
.settings-grid {
  display: grid;
  grid-auto-flow: column;
  grid-auto-rows: 1fr;
  gap: 0.2rem 2rem;
  align-items: center;
  width: fit-content;
  & > b {
    grid-column: 1;
  }
  & > span {
    grid-column: 2;
  }
}
</style>
