<template>
  <b-modal
    id="new-features-modal"
    :title="`✨ New Features`"
    size="lg"
    centered
    scrollable
    @show="onShow"
  >
    <template #modal-footer="{ close }">
      <div>
        <b-button variant="info" @click="close">Close</b-button>
      </div>
    </template>
    <div>
      <div class="text">
        <template v-if="loading">
          <b-card class="mb-3" v-for="i in [1, 2]" :key="i">
            <b-skeleton />
            <b-skeleton-img class="my-3" no-aspect height="100px" />
            <b-skeleton type="button" />
          </b-card>
        </template>
        <template v-else>
          <b-card v-for="feature in features" :key="feature.id" class="mb-3">
            <h5 class="mb-3 feature-heading">{{ feature.fieldData.name }}</h5>
            <b-badge :class="[`type-${feature.fieldData.type}`, 'type']">
              {{ getType(feature.fieldData.type) }}
            </b-badge>
            <b-container class="bv-example-row">
              <b-row>
                <b-col class="flex-grow-1">
                  <div class="mb-3 mt-3">
                    {{ feature.fieldData.description }}
                  </div>
                  <div class="mb-1 date">
                    {{ formatDate(feature.lastUpdated) }}
                  </div>
                  <div
                    v-if="
                      feature.fieldData['blog-post'] ||
                      feature.fieldData['link-to-somewhere']
                    "
                  >
                    <b-button
                      class="pl-0"
                      variant="link"
                      @click="learnMore(feature)"
                      :disabled="loadingBlogPost"
                    >
                      <b-spinner v-if="loadingBlogPost" small />
                      Learn more
                    </b-button>
                  </div>
                </b-col>
                <b-col v-if="feature.fieldData.image">
                  <img class="image" :src="feature.fieldData.image.url" />
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </template>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getFeatures, getBlogPostById } from "@/api";
import formatDate from "@/utils/formatDate";
import openFeatureModalIfNeeded from "@/components/global/globalModals/openFeatureModalIfNeeded";
import { LOCAL_STORAGE_FEATURE_KEY } from "@/constants";

const version = process.env.VUE_APP_VERSION;
export default {
  data() {
    return {
      loading: false,
      loadingBlogPost: false,
      features: [],
    };
  },

  methods: {
    formatDate,
    getType(typeId) {
      switch (typeId) {
        case "069e6f95ddb552976243120f7aba1a88":
          return "New Feature";
        case "3a7c836ddc3ef38e19e4b12b31a8a96a":
          return "Update";
        default:
          return "Fix";
      }
    },
    async learnMore(feature) {
      if (feature.fieldData["blog-post"]) {
        try {
          this.loadingBlogPost = true;
          const { data } = await getBlogPostById(
            feature.fieldData["blog-post"]
          );
          window.open(
            `https://www.essaygrader.ai/blog/${data.fieldData["slug"]}`,
            "_blank"
          );
          this.loadingBlogPost = false;
        } catch (error) {
          this.loadingBlogPost = false;

          this.$bvToast.show(error.message, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        }
      } else if (feature.fieldData["link-to-somewhere"]) {
        window.open(feature.fieldData["link-to-somewhere"], "_blank");
      }
    },
    async onShow() {
      this.loading = true;
      try {
        const { data } = await getFeatures();
        this.features = data.items;
        this.loading = false;
        localStorage.setItem(LOCAL_STORAGE_FEATURE_KEY, version);
      } catch (error) {
        this.$bvToast.show(error.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        this.loading = false;
      }
    },
  },

  mounted() {
    openFeatureModalIfNeeded(this);
  },
};
</script>

<style lang="scss" scoped>
::v-deep #new-features-modal .modal-dialog {
  max-height: calc(100% - 150px);
  margin-top: 75px;
  margin-bottom: 75px;
  min-height: calc(100% - 150px);
}

.feature-heading {
  width: 90%;
}

.image {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.type {
  position: absolute;
  top: 22px;
  right: 20px;
}

.type-069e6f95ddb552976243120f7aba1a88 {
  background-color: var(--info);
  color: var(--white);
}
.type-3a7c836ddc3ef38e19e4b12b31a8a96a {
  background-color: var(--teal);
  color: var(--white);
}

.date {
  font-size: 12px;
  color: var(--gray);
}
</style>
