<script>
import { exportToCsv } from "@/api/requests/export";
import ExportToPdf from "./export_to_pdf.vue";

export default {
  name: "ExportToCsvModal",

  extends: ExportToPdf,

  data() {
    return {
      type: "csv",
      availableOptions: [
        "status",
        "class",
        "grade",
        "name",
        "assignment",
        "studentName",
        "rubric",
      ],
      description: "Select the column data to export:",
    };
  },

  methods: {
    async exportTo(payload) {
      return exportToCsv(payload);
    },

    setDefaultOptions() {
      this.selected = this.availableOptions;
    },
  },
};
</script>

<style lang="scss">
#export-to-csv-modal {
  & .export-options {
    display: grid;
    grid-template-columns: repeat(3, 150px);
  }
}
</style>
