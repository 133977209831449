import { GrowthBook } from "@growthbook/growthbook";

const gbInstance = new GrowthBook({
  clientKey: process.env.VUE_APP_GROWTHBOOK_KEY,
  attributes: {
    // Add user attributes here
  },
  enableDevMode: process.env.NODE_ENV === "development",
});

export default async function initializeGrowthBook() {
  try {
    await gbInstance.init({ streaming: true });
    return gbInstance;
  } catch (e) {
    console.error("Error initializing GrowthBook:", e);
    return null;
  }
}
