export default {
  mounted() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  data() {
    return {
      confirmationOkTitle: "No, keep grading",
    };
  },
  methods: {
    showConfirmationLeaveModal() {
      return this.$bvModal
        .msgBoxConfirm(
          "You will lose all progress, including uploaded files, on this form. Are you sure you want to leave this page?",
          {
            title: "Are you sure you want to continue?",
            size: "md",
            buttonSize: "sm",
            okVariant: "info",
            okTitle: this.confirmationOkTitle,
            cancelTitle: "Yes, continue",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          return !value;
        })
        .catch((err) => {
          this.$showToastError(
            `Error occurred while confirmation: ${err.message}`
          );
        });
    },

    getIsDirty() {
      return false;
    },

    handleBeforeUnload(event) {
      const isDirty =
        this.forceIsDirty !== undefined ? this.forceIsDirty : this.getIsDirty();

      if (isDirty) {
        event.returnValue = "Are you sure to leave site?";
      }
    },
  },

  async beforeRouteLeave(to, from, next) {
    const isDirty =
      this.forceIsDirty !== undefined ? this.forceIsDirty : this.getIsDirty();

    if (!isDirty) {
      next();

      return;
    }

    const canLeave = await this.showConfirmationLeaveModal();

    if (canLeave) {
      next();
    } else {
      next(false);
    }
  },
};
