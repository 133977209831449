export default {
  methods: {
    trackAndRedirectToUpgrade(reason, params = {}) {
      this.$trackEvent(`upgrade_clicked_${reason}`, {
        reason,
        ...params,
      });
      this.$router.push("/account");
    },
  },
};
