<template>
  <div class="tda-sources">
    <upload-content-area
      ref="uploadArea"
      class="upload-content-area mb-0"
      label="Source Material"
      v-model="uploadType"
      :transloadit-template-id="transloaditTemplateId"
      :max-number-of-files="1000"
      @input-files="files = $event"
      @input-text="content = $event"
      @file-added="$emit('file-added', $event)"
      @file-removed="$emit('file-removed', $event)"
      description="Add the source files or text that students will use as reference material to complete this assignment."
      :accept-files="['.txt', '.pdf', '.doc', '.docx']"
    >
      <template #prepend-content>
        <div v-if="value.length > 0" class="mb-4">
          <div
            class="file d-flex align-items-center"
            v-for="(file, index) in value"
            :key="file.id"
          >
            <div class="file-name">
              <a
                v-if="file.url"
                :href="file.url"
                :download="file.name"
                target="_blank"
              >
                {{ file.name }}
              </a>

              <span v-else>{{ file.name }}</span>
            </div>
            <div
              class="file-status d-flex align-items-center ml-1"
              :class="file?.status?.includes('Error') && 'error'"
            >
              <b-icon
                v-if="!file.status"
                class="mr-2"
                icon="check-circle-fill"
              />
              {{ file.status ? file.status : "Uploaded" }}
            </div>
            <div class="close ml-auto p-1" @click="removeFile(index)">
              <b-icon icon="x" />
            </div>
          </div>
        </div>
      </template>
    </upload-content-area>
    <div v-if="uploadType === 'files'" class="text-muted small">
      Supported file types are .txt, .docx and .pdf.
    </div>

    <div class="mt-3" v-if="uploadType !== 'files'">
      <eg-form-group label="Source Name" required>
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="name"
            placeholder="Enter name"
            type="text"
            name="name"
          />
          <b-button
            :disabled="!(name.length > 0 && content.length > 0)"
            variant="info"
            class="ml-5"
            @click="addTextFile"
          >
            Add
          </b-button>
        </div>
      </eg-form-group>
    </div>
  </div>
</template>

<script>
import EgFormGroup from "@/components/global/eg_form_group.vue";
import UploadContentArea from "@/components/dashboard/upload_content_area.vue";

export default {
  components: {
    EgFormGroup,
    UploadContentArea,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      transloaditTemplateId:
        process.env.VUE_APP_ASSIGNMENTS_TRANSLOADIT_TEMPLATE_ID,
      uploadType: "files",
      files: [],
      content: "",
      name: "",
    };
  },

  watch: {
    files() {
      this.$emit("input", [
        ...this.value,
        ...this.files.map((file) => ({ ...file, status: "Ready to upload" })),
      ]);
    },
  },

  methods: {
    upload() {
      return this.$refs.uploadArea.upload();
    },

    addTextFile() {
      if (!this.name || this.content.trim() === "") {
        this.$showToastError("Please enter a name and content");

        return;
      }

      this.$emit("input", [
        ...this.value,
        {
          name: `${this.name}.txt`,
          content: this.content,
          status: "Ready to upload",
        },
      ]);
      this.content = "";
      this.$refs.uploadArea.textContent = "";
      this.name = "";
    },

    removeFile(index) {
      this.$emit(
        "input",
        this.value.slice(0, index).concat(this.value.slice(index + 1))
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.tda-sources {
  max-width: 700px;
}

.upload-content-area::v-deep .uppy-Dashboard {
  height: 325px;
}

.file {
  padding: 8px 12px;
  border-bottom: 1px solid hsla(0, 0%, 87%, 1);

  .file-name {
    color: hsla(211, 100%, 50%, 1);
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .file-status {
    color: hsla(134, 61%, 41%, 1);
  }

  .file-status.error {
    color: #dc3545;
  }
}

.close {
  cursor: pointer;
}
</style>
