<template>
  <b-card class="ai-loading-card" title="Our AI is creating your rubric...">
    <EGAICountdown :duration="60" />
    <b-skeleton width="85%"></b-skeleton>
    <b-skeleton width="55%"></b-skeleton>
    <b-skeleton width="70%"></b-skeleton>
  </b-card>
</template>

<script>
import EGAICountdown from "@/components/global/eg_ai_countdown.vue";

export default {
  name: "AiLoadingCard",
  components: {
    EGAICountdown,
  },
};
</script>

<style scoped>
.ai-loading-card {
  max-width: 800px;
}
</style>
