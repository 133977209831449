<template>
  <b-container>
    <section v-if="loading || error">
      <b-row>
        <b-col>
          <div v-if="loading" class="text-center">
            <b-spinner class="mb-3"></b-spinner>
            <p>Processing request...</p>
          </div>
        </b-col>
      </b-row>
    </section>
    <section v-else>
      <breadcrumbs :items="breadcrumbs" />
      <b-row>
        <b-col lg="12" md="8">
          <div class="d-flex justify-content-between mb-3">
            <div>
              <h1 class="mb-0">{{ assignment.title }}</h1>
              <b-badge
                v-if="assignment?.class"
                class="mr-2"
                pill
                variant="grey"
              >
                <b-icon icon="folder"></b-icon>
                {{ assignment?.class.name }}
              </b-badge>
              <b-badge class="mr-2" pill variant="grey">
                <b-icon icon="file-text"></b-icon>
                {{ assignment?.essaysCount }} essays
              </b-badge>
              <span v-if="isGoogleAssignment" class="google-icon mr-1">
                <img src="../../assets/icons/eg-google.svg" alt="" />
              </span>
              <span class="small light-grey-text">
                {{ isGoogleAssignment ? "Imported" : "Created" }} on
                {{ createdDate }}
              </span>
            </div>

            <div>
              <b-button :to="`/assignment/edit/${assignment.id}`" class="mr-2">
                Edit
              </b-button>
            </div>
          </div>
          <div v-if="essays.length > 0 || isFiltered" class="essay-box">
            <essays-table
              :essays="essays"
              :fields="$options.TABLE_FIELDS"
              :available-filters="['name', 'rubric']"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              :tableLoading="tableLoading"
              @move="showMoveEssayModal"
              @delete="deleteSingleEssay"
              @getData="fetchEssays"
            />

            <div class="d-flex justify-content-center">
              <b-pagination
                v-model="currentPage"
                :total-rows="count"
                :per-page="limit"
                @change="fetchClassWithEssays"
              >
              </b-pagination>
            </div>
          </div>

          <div v-else class="essay-box-empty">
            <h3>Empty...</h3>
            <p>No essays have been graded for this assignment.</p>
            <b-button variant="info" to="/">Grade new essay</b-button>
          </div>
        </b-col>
      </b-row>
    </section>

    <move-essay-modal
      :essayId="essayToMoveId"
      @essayMoved="rerouteToClasses"
      @moveError="showError"
    />
  </b-container>
</template>

<script>
import getBreadcrumbs from "./breadcrumbs";
import Breadcrumbs from "@/components/global/breadcrumbs.vue";
import EssaysTable from "@/components/essay/essays_table.vue";
import MoveEssayModal from "@/components/essay/modals/move_essay_modal.vue";
import { getAssignment, deleteEssay } from "@/api";
import formatDate from "@/utils/formatDate";
import fetchEssays from "@/mixins/fetchEssays";

const TABLE_FIELDS = [
  {
    key: "status",
    label: "Status",
    columnClass: "col-status",
    sortable: true,
  },
  {
    key: "name",
    label: "Essay",
    tdClass: "text-truncate",
    columnClass: "col-name",
    sortable: true,
  },
  {
    key: "Class",
    label: "Class",
    columnClass: "col-rubric",
    sortable: true,
  },
  {
    key: "Rubric",
    label: "Rubric",
    columnClass: "col-rubric",
    sortable: true,
  },
  {
    key: "actions",
    label: "",
    tdClass: "text-right",
    columnClass: "col-actions",
  },
];

export default {
  name: "ClassView",
  components: {
    Breadcrumbs,
    EssaysTable,
    MoveEssayModal,
  },
  //defaultFilters, fetchEssays, isFiltered
  mixins: [fetchEssays],
  TABLE_FIELDS,
  data() {
    return {
      loading: true,
      error: "",
      assignment: {},
      createdDate: "",
      currentPage: 1,
      classId: null,
      essayToMoveId: null,
    };
  },
  computed: {
    breadcrumbs() {
      return getBreadcrumbs(this.assignment.title || "View Assignment");
    },

    isGoogleAssignment() {
      return this.assignment.type === "googleClassroom";
    },
  },

  methods: {
    async fetchClassWithEssays() {
      this.loading = true;

      try {
        const { data } = await getAssignment(this.$route.params.id);
        await this.fetchEssays(); // automatically fetches essays and essays this.limit, this.count, this.queuedCount

        this.assignment = data;
        this.createdDate = formatDate(data.createdAt);

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.showError(error.response?.data?.error);
      }
    },
    gradedDate(date) {
      return formatDate(date);
    },
    showMoveEssayModal(essayId) {
      this.essayToMoveId = essayId;
      this.$bvModal.show("move-essay-modal");
    },
    rerouteToClasses() {
      this.$router.push("/classes");
    },
    showError(error) {
      this.$showToastError(error.response?.data?.error);
    },
    async deleteSingleEssay(essayId) {
      try {
        this.loading = true;

        await deleteEssay(essayId);

        await this.fetchClassWithEssays();

        this.showDeleteSuccess();
      } catch (error) {
        this.loading = false;
        this.showError(error.response?.data?.error);
      }
    },
    showDeleteSuccess() {
      this.$bvToast.toast(`The essay(s) were deleted successfully.`, {
        title: "Essay(s) deleted",
        variant: "success",
        autoHideDelay: 3000,
        solid: true,
      });
    },
  },
  async mounted() {
    // filter essays by class id as default
    this.defaultFilters = { AssignmentId: this.$route.params.id };
    this.fetchClassWithEssays();
  },
};
</script>
