export default function initShowToastError(Vue) {
  Vue.prototype.$showToastError = function (errorMessage, options = {}) {
    this.$root.$bvToast.toast(errorMessage, {
      variant: "danger",
      solid: true,
      ...options,
      title: options.title || "Error",
    });
  };
}
