var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-datepicker',{staticClass:"datepicker",attrs:{"no-close-on-select":"","size":"sm","placeholder":"Select date","locale":"en","date-disabled-fn":_vm.dateFromDisabled,"date-format-options":{
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    }},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}}),_c('div',{staticClass:"to"},[_vm._v("to")]),_c('b-form-datepicker',{ref:"toPicker",staticClass:"datepicker",attrs:{"no-close-on-select":"","size":"sm","placeholder":"Select date","locale":"en","date-disabled-fn":_vm.dateToDisabled,"date-format-options":{
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short',
    }},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }