<script>
export default {
  name: "EgToggleButton",
  emits: ["click"],
  props: {
    label: String,
    pressed: Boolean,
    horizontal: Boolean,
  },
};
</script>

<template>
  <b-button
    :pressed="pressed"
    @click="$emit('click')"
    class="new-rubric-button"
    :style="{
      flexDirection: this.horizontal ? 'row' : 'column',
    }"
  >
    <slot></slot>
    {{ label }}
  </b-button>
</template>

<style scoped lang="scss">
.new-rubric-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  background: white;
  border: 1px #dfdfdf solid;

  &[aria-pressed="true"] {
    background: #f9f9ff !important;
    border: 1px var(--info, #714bd4) solid !important;
  }

  &:hover {
    background: #f9f9ff !important;
  }
}
</style>
