<template>
  <b-container>
    <div
      v-if="assignments.length > 0 && !loading"
      class="text-right svg-container"
    >
      <img
        src="../../assets/illustrations/assignment-illustration.svg"
        alt=""
      />
    </div>
    <section v-if="loading">
      <b-row class="mt-4">
        <b-col class="mb-3" cols="4">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
        <b-col class="mb-3" cols="4">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
        <b-col class="mb-3" cols="4">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
        <b-col cols="4">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
        <b-col cols="4">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
        <b-col cols="4">
          <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
        </b-col>
      </b-row>
    </section>
    <section>
      <template
        v-if="
          assignments.length > 0 ||
          debouncedSearchText !== '' ||
          selectedClass !== null
        "
      >
        <b-row class="mb-3">
          <b-col lg="8" md="12">
            <h1>Assignments</h1>
          </b-col>
          <b-col class="text-right create-rubric-column" lg="4" md="12">
            <b-button variant="info" to="/assignment/new">
              New assignment
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <div class="search-input-wrapper ml-3 mr-3">
            <b-form-input
              class="pr-4"
              size="sm"
              type="text"
              placeholder="Filter by assignment title"
              ref="searchInput"
              v-model="searchText"
              @input="changeSearch($event)"
            ></b-form-input>
            <b-icon
              v-if="searchText"
              class="clear-input"
              icon="x-circle-fill"
              style="color: #adb5bd"
              @click="clearSearch"
            />
          </div>
          <class-select
            class="select-class"
            size="sm"
            :value="selectedClass"
            @input="selectClass"
          />
        </b-row>
        <b-row>
          <b-col v-for="assignment in assignments" :key="assignment.id" lg="4">
            <assignment-card :assignment="assignment" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-center">
              <b-pagination
                v-model="currentPage"
                :total-rows="count"
                :per-page="limit"
                @change="fetchAssignments"
              >
              </b-pagination>
            </div>
          </b-col>
        </b-row>
      </template>
      <empty-state
        v-else-if="
          !loading &&
          assignments.length == 0 &&
          debouncedSearchText === '' &&
          selectedClass === null
        "
        class="mt-5"
        title="No assignments yet"
        description="Use assignments to organize essays. Assignments can make grading easier with advanced instructions."
        button-text="New assignment"
        button-path="/assignment/new"
      >
        <img
          src="../../assets/illustrations/empty-state-assignment.svg"
          alt="EssayGrader Empty State"
        />
      </empty-state>
    </section>
  </b-container>
</template>

<script>
import { getAssignments } from "@/api";
import AssignmentCard from "@/components/assignment/assignment_card.vue";
import EmptyState from "@/components/global/empty_state.vue";
import debounce from "@/utils/debounce";
import ClassSelect from "@/components/classes/class_select";

export default {
  name: "AssignmentView",

  components: {
    AssignmentCard,
    ClassSelect,
    EmptyState,
  },

  data() {
    return {
      selectedClass: null,
      searchText: "",
      debouncedSearchText: "",
      loading: true,
      assignments: [],
      count: 0,
      limit: null,
      currentPage: 1,
    };
  },

  methods: {
    clearSearch() {
      this.searchText = "";
      this.debouncedSearchText = "";
      this.currentPage = 1;
      this.fetchAssignments();
    },

    selectClass(value) {
      this.selectedClass = value;
      this.currentPage = 1;
      this.fetchAssignments();
    },

    changeSearch: debounce(function (value = "") {
      if (
        value.length >= 2 ||
        (this.debouncedSearchText.length >= 2 && value.length === 0)
      ) {
        this.debouncedSearchText = value;
        this.currentPage = 1;
        this.fetchAssignments();
      }
    }, 500),

    async fetchAssignments() {
      const params = {
        page: this.currentPage,
      };

      if (this.debouncedSearchText) {
        params.title = this.debouncedSearchText;
      }

      if (this.selectedClass) {
        params.ClassId = this.selectedClass.id;
      }

      try {
        const { data } = await getAssignments(params);

        this.assignments = data.rows;
        this.limit = data.limit;
        this.count = data.count;

        this.loading = false;
        this.error = false;
      } catch (error) {
        this.error = error.response?.data?.error;
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchAssignments();
  },
};
</script>

<style lang="scss" scoped>
.select-class {
  border-radius: 4px;
  background-color: white;
  width: 200px;
}

.search-input-wrapper {
  position: relative;
  width: 200px;

  .clear-input {
    position: absolute;
    bottom: 8px;
    right: 10px;
    cursor: pointer;
  }
}
</style>
