import * as Sentry from "@sentry/vue";

export default function initSentry(Vue) {
  Sentry.init({
    Vue,
    dsn: "https://026ab1ddd5871b2b08750822799c8fc5@o4507063370121216.ingest.us.sentry.io/4507063385260032",
    environment: process.env.NODE_ENV,
    enabled: process.env.NODE_ENV !== "development",
  });
}
