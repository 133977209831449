<script>
import IconBadge from "@/components/global/icon_badge.vue";

export default {
  name: "AdvancedRubricBadge",
  components: { IconBadge },
};
</script>

<template>
  <IconBadge>
    <template #icon>
      <b-icon icon="bookmark-check" />
    </template>
    Advanced
  </IconBadge>
</template>
