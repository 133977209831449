<script>
import AdvancedRubricBadge from "@/components/rubrics/advanced_rubric_badge.vue";
import IconBadge from "@/components/global/icon_badge.vue";
import formatDate from "../../utils/formatDate";
import { formatAuthorValue } from "@/utils/format";

export default {
  name: "RubricListItem",
  emits: ["shareRubric"],
  components: { IconBadge, AdvancedRubricBadge },
  props: {
    rubric: {
      type: Object,
      required: true,
    },
    hideButtons: Boolean,
    isPlatformRubric: Boolean,
  },
  methods: {
    formatAuthorValue,
    formatDate,
  },
  computed: {
    userDomain() {
      return this.$store.state.user?.domain?.url || "your";
    },
  },
};
</script>

<template>
  <b-list-group-item class="d-flex justify-content-between align-items-center">
    <div>
      <router-link :to="{ name: 'preview-rubric', params: { id: rubric.id } }">
        {{ rubric.name }}
      </router-link>
      <span class="ml-4 text-date">
        Created on {{ formatDate(rubric.createdAt) }} by
        <strong>
          {{ formatAuthorValue(rubric.author, $store) }}
        </strong>
      </span>
    </div>
    <div class="d-flex align-items-center">
      <IconBadge
        v-if="rubric.shareLink?.isSharedWithDomain"
        v-b-tooltip.hover
        class="mx-1"
        :title="`Anyone with ${userDomain} domain or the link can view`"
      >
        <template #icon>
          <img src="../../assets/icons/school.svg" alt="" />
        </template>
        Shared with Domain
      </IconBadge>
      <IconBadge
        v-else
        v-b-tooltip.hover
        class="mx-1"
        :title="
          isPlatformRubric ? undefined : 'Anyone with the private link can view'
        "
      >
        <template #icon>
          <b-icon icon="lock" />
        </template>
        {{ isPlatformRubric ? "Anyone on EssayGrader" : "Private link" }}
      </IconBadge>
      <AdvancedRubricBadge v-if="rubric.type === 'Advanced'" class="mx-1" />
      <b-dropdown
        v-if="!hideButtons"
        class="ml-4"
        no-caret
        dropleft
        size="sm"
        variant="outline-secondary"
      >
        <template #button-content>
          <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
        </template>

        <b-dropdown-item
          :to="{ name: 'edit-rubric', params: { id: rubric.id } }"
        >
          Edit
        </b-dropdown-item>
        <b-dropdown-item @click="$emit('shareRubric', rubric)">
          <b-icon icon="link45deg" />
          Share
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </b-list-group-item>
</template>

<style scoped lang="scss"></style>
