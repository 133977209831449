<template>
  <b-form-group :label-for="labelFor">
    <template #label v-if="label">
      {{ label }}
      <span v-if="required" class="red-text">*</span>
      <p v-if="showDescription" class="text-muted small mb-0 mt-0">
        <slot v-if="$slots.description" name="description"></slot>
        <template v-else>
          {{ description }}
        </template>
      </p>
    </template>
    <slot />
  </b-form-group>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },

    labelFor: {
      type: String,
    },

    description: {
      type: String,
      default: "",
    },

    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showDescription() {
      return this.$slots.description || this.description;
    },
  },
};
</script>
