<template>
  <b-button
    v-if="client"
    variant="info"
    :class="['login-btn', native ? 'native-btn' : '']"
    :disabled="loading || !client"
    @click="() => !loading && client.requestCode()"
  >
    <b-spinner v-if="loading" small class="mr-2"></b-spinner>
    <span v-else>
      <svg
        v-if="native"
        class="mr-2"
        xmlns="http://www.w3.org/2000/svg"
        width="18px"
        height="18px"
        viewBox="0 0 256 262"
      >
        <path
          fill="#4285f4"
          d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622l38.755 30.023l2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
        />
        <path
          fill="#34a853"
          d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055c-34.523 0-63.824-22.773-74.269-54.25l-1.531.13l-40.298 31.187l-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
        />
        <path
          fill="#fbbc05"
          d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82c0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602z"
        />
        <path
          fill="#eb4335"
          d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0C79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
        />
      </svg>
      <b-icon v-else icon="google" small class="mr-2"></b-icon>
    </span>
    <slot />
    <span v-if="!$slots.default">Login with Google</span>
  </b-button>
</template>

<script>
import gTagMixin from "./gTagMixin";
import { googleLoginCallback, googlePermissionsCallback, getUser } from "@/api";
const DEFAULT_SCOPE = ["openid", "email", "profile"];

export default {
  mixins: [
    // this.gtag_report_conversion
    // this.bing_report_conversion
    gTagMixin,
  ],

  props: {
    scope: {
      type: Array,
      default: () => [],
    },

    type: {
      type: String,
      default: "login",
    },

    native: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      client: null,
      loading: false,
    };
  },

  created() {
    const config = {
      client_id:
        process.env.VUE_APP_CLIENT_ID ||
        "579381534512-e4eee7r7p8i1pk1bi96epgts4tfsq0bg.apps.googleusercontent.com",
      scope: [...DEFAULT_SCOPE, ...this.scope].join(" "),
      ux_mode: "prompt",
      callback: async (response) => {
        if (response.error) {
          this.$showToastError(
            "There was a problem connecting to Google. Please try again",
            {
              title: "Google Error",
            }
          );

          return;
        }

        const isLogin = this.type === "login";
        const isRegistration = this.type === "registration";
        const isLoginOrRegistration = isLogin || isRegistration;
        this.loading = true;
        try {
          const { data } = isLoginOrRegistration
            ? await googleLoginCallback(response)
            : await googlePermissionsCallback(response);

          let user = {};

          if (isLoginOrRegistration) {
            await this.$store.dispatch("setToken", data.token);
            const { data: userData } = await getUser(/* data.id */);
            user = userData;
            this.$store.dispatch("setUser", userData);
          }

          if (isRegistration) {
            this.gtag_report_conversion();
            this.bing_report_conversion();
          }

          this.loading = false;
          this.$emit("done", isLoginOrRegistration ? user : data);
        } catch (error) {
          this.loading = false;
        }
      },
    };

    this.client = window?.google?.accounts?.oauth2?.initCodeClient(config);
    window.onload = () => {
      if (this.client) return;

      this.client = window.google.accounts.oauth2.initCodeClient(config);
    };
  },
};
</script>

<style lang="scss" scoped>
.native-btn {
  border: 1px solid #c8c8c8;
  background-color: transparent;
  color: #212529;

  &:hover {
    opacity: 0.75;
  }
}
</style>
