<script>
export default {
  name: "RubricCriteriaExpandButton",
  props: {
    value: Boolean,
  },
  emits: ["click"],
};
</script>

<template>
  <b-button
    class="expand-button"
    variant="link"
    size="sm"
    @click="$emit('click')"
  >
    <div v-if="value">
      <b-icon class="position-absolute" flip-h icon="arrows-angle-contract" />
      <b-icon icon="arrows-angle-contract" />
    </div>

    <b-icon v-else icon="arrows-fullscreen" />
  </b-button>
</template>

<style scoped lang="scss">
.expand-button {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 0;
  background: #ffffffc0;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
</style>
