<template>
  <div>
    <p>{{ timeRemaining }} seconds remaining.</p>
  </div>
</template>

<script>
export default {
  name: "CountdownTimer",
  props: {
    duration: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return {
      timeRemaining: this.duration,
      countdownInterval: null,
    };
  },
  mounted() {
    this.startCountdown();
  },
  beforeDestroy() {
    this.stopCountdown();
  },
  methods: {
    startCountdown() {
      this.countdownInterval = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--;
        } else {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.countdownInterval);
    },
  },
};
</script>

<style scoped>
/* Add any styles for the timer here */
</style>
