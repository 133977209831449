<template>
  <b-modal
    id="essay-rename-modal"
    title="Edit essay name"
    size="lg"
    centered
    @show="onShow"
    @hide="onHide"
  >
    <template #modal-footer="{ close }">
      <div class="d-flex align-items-center justify-content-end">
        <b-button variant="secondary" @click="close"> Cancel </b-button>
        <b-button
          class="ml-2"
          :disabled="name.length === 0 || loading"
          variant="info"
          @click="onSave"
        >
          Save
        </b-button>
      </div>
    </template>

    <div>
      <b-form-input
        v-model="name"
        placeholder="Enter name"
        type="text"
      ></b-form-input>
    </div>
  </b-modal>
</template>

<script>
import { updatesEssay } from "@/api";

export default {
  props: {
    essay: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      name: "",
      loading: false,
    };
  },

  methods: {
    async onSave() {
      if (this.name.length === 0) {
        this.$showErrorToast("The essay name cannot be empty.");

        return;
      }

      this.loading = true;
      try {
        await updatesEssay(this.essay.id, { name: this.name });

        this.loading = false;
        this.$root.$bvToast.toast("The essay name was updated successfully", {
          title: "Success",
          variant: "success",
        });
        this.$emit("updated");
        this.$bvModal.hide("essay-rename-modal");
      } catch (error) {
        this.loading = false;

        this.showErrorToast(error?.response?.data?.error);
      }
    },

    onShow() {
      this.name = this.essay.name;
    },

    onHide() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss">
.btn.try-again {
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: baseline;
}
</style>
