<script>
export default {
  name: "SubscriptionCancelModal",
  props: {
    cancelSubscription: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selected: [],
      otherText: "",
      toolsText: "",
      loading: false,
      options: [
        {
          value: "expensive",
          text: "It was expensive.",
        },
        {
          value: "inaccurate",
          text: "The AI grading wasn't accurate.",
        },
        {
          value: "notEnoughSupport",
          text: "Not enough product support.",
        },
      ],
    };
  },

  methods: {
    handleClick() {
      this.loading = true;
      const reasons = this.selected.map((value) => {
        const reason = {
          reason: value,
        };

        if (value === "other") {
          reason.text = this.otherText;
        } else if (value === "switchingToAnother") {
          reason.text = this.toolsText;
        }

        return reason;
      });

      this.cancelSubscription({ reasons });
    },
  },
};
</script>

<template>
  <b-modal
    id="subscription_cancel_modal"
    title="We are sorry to see you go."
    size="lg"
    ok-variant="danger"
    centered
    no-stacking
    hide-footer
  >
    <p>Please let us know the reason for cancelling:</p>

    <b-form-checkbox-group
      v-model="selected"
      name="reason"
      class="d-flex flex-column"
    >
      <b-form-checkbox
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.text }}
      </b-form-checkbox>
      <b-form-checkbox value="switchingToAnother">
        <div>
          <div class="mr-3">I am switching to a different AI grading tool.</div>
          <div>
            <b-form-input
              v-if="selected.includes('switchingToAnother')"
              v-model="toolsText"
              size="sm"
              placeholder="Enter tools name"
            />
          </div>
        </div>
      </b-form-checkbox>
      <b-form-checkbox value="other">
        <div class="d-flex align-items-center">
          <div class="mr-3">Other</div>
          <b-form-input
            v-if="selected.includes('other')"
            v-model="otherText"
            size="sm"
            placeholder="Enter your reason"
          />
        </div>
      </b-form-checkbox>
    </b-form-checkbox-group>

    <div class="d-flex justify-content-end">
      <b-button
        variant="info"
        @click="() => !loading && handleClick()"
        :disabled="loading"
      >
        <b-spinner v-if="loading" small class="mr-2"></b-spinner>
        Submit
      </b-button>
    </div>
  </b-modal>
</template>
