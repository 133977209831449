import openFeatureModalIfNeeded from "@/components/global/globalModals/openFeatureModalIfNeeded";
import { createRubric, getUser } from "@/api";
import initCrisp from "@/init/crisp";
import { initCanny } from "@/init/canny";

export default {
  inject: ["growthBook"],
  methods: {
    resetLocalStorageSavedFormData() {
      localStorage.removeItem("suggestion_form:selectedAssignment");
      localStorage.removeItem("suggestion_form:selectedClass");
      localStorage.removeItem("suggestion_form:selectedRubric");
    },
    async redirectToRoute(vm, user) {
      this.resetLocalStorageSavedFormData();
      this.growthBook?.setAttributes({ email: user?.email });

      const { data } = await getUser("me");
      await this.$store.dispatch("setUser", data);
      initCrisp(data);
      initCanny(data);

      const newRubricData = localStorage.getItem("share:newRubricData");
      const useRubricData = localStorage.getItem("share:useRubricData");

      if (newRubricData) {
        localStorage.removeItem("share:newRubricData");
        const rubricData = JSON.parse(newRubricData);
        const { data } = await createRubric(rubricData);
        await this.$router.push(`/rubric/edit/${data.id}`);
      } else if (useRubricData) {
        localStorage.removeItem("share:useRubricData");
        localStorage.setItem("suggestion_form:selectedRubric", useRubricData);
        await this.$router.push({ path: "/upload-essays" });
      } else {
        await vm.$router.replace({ name: "dashboard" });
      }

      openFeatureModalIfNeeded(vm);
    },
  },
};
