import { getAllEssays } from "../api";

export default {
  data() {
    return {
      essays: [],
      limit: null,
      count: 0,
      currentPage: 1,
      queuedCount: 0,
      sortBy: "createdAt",
      sortDesc: true,
      defaultFilters: {},
      filters: {
        name: "",
        ClassId: null,
        RubricId: null,
      },
      tableLoading: true,
      initialLoading: true,
    };
  },

  computed: {
    isFiltered() {
      return Object.keys({ ...this.filters, ...this.defaultFilters }).some(
        (key) => this.filters[key]
      );
    },
  },

  methods: {
    resetEssaysData() {
      this.essays = [];
      this.limit = null;
      this.count = 0;
      this.currentPage = 1;
      this.queuedCount = 0;
      this.sortBy = "createdAt";
      this.sortDesc = true;
      this.defaultFilters = {};
      this.filters = {
        name: "",
        ClassId: null,
        RubricId: null,
      };
    },

    async fetchEssays(ctx = {}) {
      const { sortBy, sortDesc, filters } = ctx;

      if (sortBy && sortDesc !== undefined) {
        this.sortDesc = sortDesc;
        this.sortBy = sortBy;
      }

      if (filters) {
        this.filters = filters;
        this.currentPage = 1;
      }

      this.tableLoading = true;

      try {
        const { data } = await getAllEssays({
          page: this.currentPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          filters: { ...this.filters, ...this.defaultFilters },
        });

        this.essays = data.rows;
        this.limit = data.limit;
        this.count = data.count;
        this.queuedCount = data.queuedCount || 0;

        this.error = "";
        this.tableLoading = false;
        this.initialLoading = false;
      } catch (error) {
        this.tableLoading = false;
        this.error = error.response?.data?.error;
      }
    },
  },
};
