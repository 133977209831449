<script>
import { setShareRubricByDomain } from "@/api";
import debounce from "../../utils/debounce";

export default {
  name: "RubricShareModal",
  props: {
    rubric: {
      type: Object,
    },
  },
  emits: ["invalidateRubric"],
  data() {
    return {
      checkBoxValue: this.rubric?.shareLink.isSharedWithDomain ?? false,
    };
  },
  watch: {
    rubric: function (val) {
      if (val) {
        this.checkBoxValue = this.isShared;
      }
    },
  },
  computed: {
    url() {
      if (!this.rubric) {
        return "";
      }
      const href = this.$router.resolve({
        name: "shared-rubric",
        params: { slug: this.rubric.shareLink.slug },
      }).href;

      return `${window.location.protocol}//${window.location.host}${href}`;
    },
    isShared() {
      return this.rubric?.shareLink.isSharedWithDomain ?? false;
    },
    canShare() {
      return this.$store.state.user.domain?.shareable || false;
    },

    userDomain() {
      return this.$store.state.user.domain?.url;
    },
  },
  methods: {
    onCopy() {
      navigator.clipboard.writeText(this.url);
      this.$bvToast.toast("Rubric Link copied", {
        noCloseButton: true,
      });
    },

    setShare: debounce(function (value) {
      setShareRubricByDomain(this.rubric.shareLink.id, value).then(() => {
        this.$emit("invalidateRubric", this.rubric);
        this.$bvToast.toast("Sharing permissions saved successfully.", {
          noCloseButton: true,
          variant: "success",
        });
      });
    }, 500),
  },
};
</script>

<template>
  <b-modal id="rubric-share-modal" size="md" centered no-stacking>
    <template #modal-title>
      <div>
        <b-icon icon="link45deg" />
        Share rubric
      </div>
    </template>

    <label>Rubric Link:</label>
    <b-input-group>
      <b-form-input readonly type="text" :value="url"></b-form-input>
      <b-input-group-append>
        <b-button variant="info" @click="onCopy">Copy</b-button>
      </b-input-group-append>
    </b-input-group>
    <div v-if="canShare" class="d-flex align-items-center mt-3">
      <b-form-checkbox
        @change="setShare"
        v-model="checkBoxValue"
        switch
        size="md"
      />
      <div class="ml-2 d-flex flex-column">
        Anyone with {{ userDomain }} domain can access
        <span class="text-secondary">
          <small>
            Enabling this will give access to any account using the same domain.
          </small>
        </span>
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="outline-info"
        @click="$bvModal.hide('rubric-share-modal')"
      >
        Close</b-button
      >
    </template>
  </b-modal>
</template>
