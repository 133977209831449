import api from "@/api/api";

export const getClassroomClasses = (googleId) => {
  return api.get(`/google-classroom/${googleId}/classes`);
};

export const getClassroomAssignments = (googleId, classId) => {
  return api.get(
    `/google-classroom/${googleId}/classes/${classId}/assignments`
  );
};

export const getClassroomSubmissions = (googleId, classId, assignmentId) => {
  return api.get(
    `/google-classroom/${googleId}/classes/${classId}/assignments/${assignmentId}/submissions`
  );
};

export const getDraftAssignment = (googleId, classId, assignmentId) => {
  return api.get(
    `/google-classroom/${googleId}/classes/${classId}/assignments/${assignmentId}`
  );
};

export const importClassroomSubmissions = (
  googleId,
  classId,
  assignmentId,
  payload
) => {
  return api.put(
    `/google-classroom/${googleId}/classes/${classId}/assignments/${assignmentId}/submissions/import`,
    payload
  );
};
