<template>
  <b-container>
    <section>
      <breadcrumbs :items="breadcrumbs" />
      <b-row>
        <b-col lg="8">
          <h1>Edit assignment</h1>
        </b-col>
        <b-col class="text-right create-rubric-column" lg="4" md="12">
          <b-button
            v-if="this.assignment"
            class="mr-2"
            variant="info"
            :to="`/assignment/${this.assignment.id}`"
          >
            View essays
          </b-button>
        </b-col>
      </b-row>
      <assignment-form v-if="assignment" :assignment="assignment" />
    </section>
  </b-container>
</template>

<script>
import getBreadcrumbs from "./breadcrumbs";
import { getAssignment } from "@/api";
import Breadcrumbs from "@/components/global/breadcrumbs.vue";
import AssignmentForm from "@/components/assignment/assignment_form.vue";

export default {
  name: "EditAssignmentView",

  components: {
    Breadcrumbs,
    AssignmentForm,
  },

  data() {
    return {
      assignment: null,
    };
  },

  computed: {
    breadcrumbs() {
      return getBreadcrumbs("Edit assignment", this.assignment);
    },
  },

  methods: {
    async fetchAssignment() {
      this.loading = true;

      try {
        const { data } = await getAssignment(this.$route.params.id);

        this.assignment = data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.showError(error.response?.data?.error);
      }
    },
  },

  created() {
    this.fetchAssignment();
  },
};
</script>
