<template>
  <b-row>
    <b-col lg="12">
      <b-form>
        <eg-form-group v-if="!hideTitle" label="Title" required>
          <b-form-input
            v-model="innerAssignment.title"
            placeholder="Enter title"
            type="text"
          />
        </eg-form-group>
        <eg-form-group v-if="!hideClass" label="Class">
          <class-select
            v-model="innerAssignment.class"
            withSearch
            @click:create="
              $router.push(`/class/new?redirectTo=${$route.fullPath}`)
            "
          />
        </eg-form-group>

        <eg-form-group
          v-if="!hideInstructions"
          label="Instructions"
          description="Add the instructions (writing prompt) given to students to complete this assignment."
        >
          <b-form-textarea
            v-model="innerAssignment.tdaInstructions"
            :rows="4"
            max-rows="12"
          ></b-form-textarea>
        </eg-form-group>

        <tda-sources
          ref="tdaSources"
          :value="innerAssignment.tdaSources"
          @input="updateTda"
          @file-added="uppyFiles = $event"
          @file-removed="uppyFiles = $event"
        />
      </b-form>

      <div v-if="!hideActions" class="text-right mt-4">
        <b-button @click="$router.back()" class="mr-2">
          {{ innerAssignment.id ? "Back" : "Cancel" }}
        </b-button>
        <b-button
          v-if="innerAssignment.id"
          :disabled="!canDelete"
          variant="danger"
          class="mr-2"
          @click="confirmDeletion"
        >
          Delete
          <b-icon
            v-if="innerAssignment.id && !canDelete"
            v-b-popover.hover.top="
              'Assignment can only be deleted when no essays exist for the assignment.'
            "
            class="ml-2 delete-question-icon"
            icon="question-circle"
          ></b-icon>
        </b-button>
        <b-button
          type="submit"
          variant="info"
          :disabled="isDisabled || loading"
          @click="onSubmit"
        >
          <b-spinner v-if="loading" small></b-spinner>
          {{ innerAssignment.id ? "Update" : "Create" }} Assignment
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  createFiles,
  deleteAssignment,
  updateAssignment,
  createAssignment,
} from "../../api";
import TdaSources from "./tda_sources.vue";
import EgFormGroup from "@/components/global/eg_form_group.vue";
import ClassSelect from "@/components/classes/class_select";

function getDuplicates(arr) {
  const seen = new Set();
  const duplicates = [];

  arr.forEach((item) => {
    const identifier = item.name;
    if (seen.has(identifier)) {
      duplicates.push(item);
    } else {
      seen.add(identifier);
    }
  });

  return duplicates;
}

export default {
  name: "AssignmentForm",

  components: {
    EgFormGroup,
    ClassSelect,
    TdaSources,
  },

  props: {
    assignment: {
      type: Object,
    },

    hideInstructions: {
      type: Boolean,
      default: false,
    },

    hideTitle: {
      type: Boolean,
      default: false,
    },

    hideClass: {
      type: Boolean,
      default: false,
    },

    hideActions: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      replaceExistingFiles: false,
      uppyFiles: [],
      innerAssignment: {
        title: "", // required
        class: null, // optional
        tdaInstructions: "", // optional
        tdaSources: [], // optional; File ids array; min 1 item if provided;
      },
    };
  },

  computed: {
    isEdit() {
      return !!this.assignment;
    },

    canDelete() {
      return this.assignment.essaysCount === 0;
    },

    isDisabled() {
      if (!this.isEdit) {
        return this.innerAssignment.title === "";
      }

      return (
        this.innerAssignment.title === this.assignment.title &&
        this.innerAssignment?.class?.id === this.assignment?.class?.id &&
        (this.innerAssignment.tdaInstructions === null
          ? ""
          : this.innerAssignment.tdaInstructions) ===
          (this.assignment.tdaInstructions === null
            ? ""
            : this.assignment.tdaInstructions) &&
        this.innerAssignment.tdaSources.length ===
          this.assignment.tdaSources.length &&
        this.innerAssignment.tdaSources.every(
          (source, index) => source.id === this.assignment.tdaSources[index].id
        ) &&
        (this.uppyFiles.length === 0 ||
          this.uppyFiles.filter((file) => !file.progress.uploadComplete)
            .length === 0)
      );
    },
  },

  methods: {
    showAndWaitDuplicateModal(duplicateNameFiles) {
      // // this.replaceExistingFiles = true;

      // return new Promise((resolve) => {
      const h = this.$createElement;
      const titleVNode = h("div", {
        domProps: { innerHTML: "Upload options" },
      });
      const messageVNode = h("div", { class: ["foobar"] }, [
        h("div", {}, [
          `${duplicateNameFiles
            .map((file) => file.name)
            .join(
              ", "
            )} already exists as a source. Do you want to replace the existing file with a new version or upload both files?`,
        ]),
        h("b-form-radio-group", {
          class: ["mt-3"],
          props: {
            stacked: true,
            checked: true,
            name: "duplicate-name",
            options: [
              { text: "Replace existing file", value: true },
              { text: "Upload both files", value: false },
            ],
          },
          on: {
            input: (value) => {
              this.replaceExistingFiles = value;
            },
          },
        }),
      ]);
      // We must pass the generated VNodes as arrays
      return this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: [titleVNode],
          okTitle: "Upload",
          okVariant: "info",
          cancelTitle: "Cancel",
          buttonSize: "sm",
          centered: true,
          size: "md",
        })
        .then((value) => {
          if (value) {
            return;
          } else {
            return "Please delete duplicate files and try again.";
          }
        });
      // });
    },

    updateTda(tdaSources) {
      this.innerAssignment.tdaSources = tdaSources;
    },

    async confirmDeletion() {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete this assignment?", {
          title: "Delete Confirmation",
          size: "ml",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          cancelVariant: "outline-secondary",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((needDelete) => {
          needDelete && this.deleteSingleAssignment();
        });
    },

    async deleteSingleAssignment() {
      try {
        this.loading = true;

        await deleteAssignment(this.assignment.id);

        this.$root.$bvToast.toast(`The assignment was deleted successfully.`, {
          title: "Successfully deleted",
          variant: "success",
          toaster: "b-toaster-top-center",
        });

        this.$router.push("/assignments");

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.showError(error.response?.data?.error);
      }
    },

    async onSubmit() {
      try {
        if (!this.innerAssignment.title) {
          this.$showToastError("Title is required");

          return;
        }

        this.loading = true;
        this.innerAssignment.tdaSources =
          this.innerAssignment.tdaSources.filter(
            (source) => source.status !== "Error"
          );
        await this.$refs.tdaSources.upload();

        const duplicateNameFiles = getDuplicates(
          this.innerAssignment.tdaSources
        );

        if (duplicateNameFiles.length > 0) {
          const error = await this.showAndWaitDuplicateModal(
            duplicateNameFiles
          );

          if (error) {
            this.loading = false;

            return this.$showToastError(error);
          }
        }

        const newFilesData = this.innerAssignment.tdaSources.filter(
          (source) => !source.id
        );

        newFilesData.forEach((source) => {
          source.status = "Uploading...";
        });

        if (newFilesData.length > 0) {
          const { data: newFiles } = await createFiles(
            newFilesData.map((file) => {
              return {
                name: file.name,
                url: file.fileUrl,
                content: file.content,
              };
            })
          );

          newFilesData.forEach((source, index) => {
            source.id = newFiles[index].id;
            source.status = null;
          });
        }

        const sourcesIds = this.innerAssignment.tdaSources.map(
          (source) => source.id
        );

        const payload = {
          title: this.innerAssignment.title,
        };

        if (this.innerAssignment.class) {
          payload.ClassId = this.innerAssignment.class.id;
        }

        if (this.innerAssignment.tdaInstructions) {
          payload.tdaInstructions = this.innerAssignment.tdaInstructions;
        }

        if (sourcesIds.length > 0) {
          payload.tdaSources = sourcesIds;
        }

        let res = {};
        if (this.innerAssignment.id) {
          payload.id = this.innerAssignment.id;
          res = await updateAssignment(payload);
        } else {
          res = await createAssignment(payload);
        }

        this.loading = false;

        this.$root.$bvToast.toast(
          `The assignment was ${
            this.innerAssignment.id ? "updated" : "created"
          } successfully.`,
          {
            title: "Success!",
            variant: "success",
            toaster: "b-toaster-top-center",
          }
        );

        if (this.hideActions) {
          return; // stay on google import or other embedded view.
        }

        if (this.innerAssignment.id) {
          return; // we are on the edit view, stay on the edit view.
        }

        let redirectTo;

        if (this.$route.query.redirectTo) {
          redirectTo = this.$route.query.redirectTo;
          const redirectToHasQuery = redirectTo.includes("?");
          redirectTo = `${redirectTo}${
            redirectToHasQuery ? "&" : "?"
          }assignmentId=${res.data.id}`;
        } else {
          redirectTo = `/assignment/edit/${res.data.id}`;
        }

        this.$router.push(redirectTo);
      } catch (error) {
        this.loading = false;
        let message = error.response?.data?.error;

        if (error.response?.data?.meta?.message.includes("Data too long")) {
          message = "One of the sources has long data";
          this.innerAssignment.tdaSources
            .filter((source) => !source.id)
            .forEach((source) => {
              source.status = "Error";
            });
        }

        this.$showToastError(message);
      }
    },
  },

  created() {
    this.assignment &&
      (this.innerAssignment = {
        id: this.assignment.id,
        title: this.assignment.title, // required
        class: this.assignment?.class, // optional
        tdaInstructions: this.assignment.tdaInstructions, // optional
        tdaSources: [...this.assignment.tdaSources], // optional; File ids array; min 1 item if provided;
      });
  },
};
</script>

<style lang="scss" scoped>
.delete-question-icon {
  color: inherit;
}
</style>
