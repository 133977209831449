<template>
  <div>
    <b-container class="upload-essay-view">
      <section>
        <div>
          <b-row>
            <b-col>
              <b-alert v-if="error" variant="danger" show>{{ error }}</b-alert>
              <h1 class="mb-2">Start grading</h1>

              <essay-submission-form
                ref="form"
                :selected-rubric="selectedRubric"
                @essaySubmitted="getFeedback"
              />
            </b-col>
          </b-row>
        </div>
      </section>
    </b-container>

    <rubric-library
      v-model="selectedRubric"
      @input="selectRubric"
      show-add-new
    />
  </div>
</template>

<script>
import leaveMixin from "@/mixins/leaveMixin";
import EssaySubmissionForm from "@/components/dashboard/essay_submission_form.vue";
import RubricLibrary from "@/components/rubrics/rubric_library.vue";
import { feedback } from "@/api";

export default {
  name: "UploadEssaysView",

  components: {
    EssaySubmissionForm,
    RubricLibrary,
  },

  mixins: [leaveMixin],

  data() {
    return {
      error: "",
      hasUpgraded: "",
      selectedRubric: JSON.parse(
        localStorage.getItem("suggestion_form:selectedRubric")
      ),
    };
  },

  computed: {
    featureFlags() {
      return this.$store.state.featureFlags;
    },
  },

  methods: {
    selectRubric(rubric) {
      localStorage.setItem(
        "suggestion_form:selectedRubric",
        JSON.stringify(rubric)
      );
    },
    // override for leave guard
    getIsDirty() {
      const isDirty = this.$refs.form.isDirty;

      return isDirty === undefined ? true : isDirty;
    },

    async getFeedback(payload) {
      try {
        await feedback(payload);

        this.error = "";
        // always ignore leave guard
        this.forceIsDirty = false;
        this.$router.push("/essays");
      } catch (error) {
        // error handled on the interceptors
        return;
      }
    },
  },
};
</script>
