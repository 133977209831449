<script>
export default {
  name: "SimpleRubricPreviewTable",
  props: {
    criteria: {
      type: Array,
      required: true,
    },
    isDefault: Boolean,
  },
};
</script>

<template>
  <b-table-simple bordered outlined striped hover responsive>
    <b-thead>
      <b-tr>
        <b-th>Grading Criteria</b-th>
        <b-th v-if="!isDefault">Grade</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr v-for="criterion in criteria" :key="criterion.id">
        <b-td>{{ criterion.point }}</b-td>
        <b-td v-if="!isDefault">{{ criterion.scale }}</b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<style scoped lang="scss"></style>
