<script>
import RubricCriteriaExpandButton from "./RubricCriteriaExpandButton.vue";

export default {
  name: "ExpandableTextarea",
  props: { value: String, canValidate: Boolean },
  data() {
    return {
      overscroll: false,
      expanded: false,
    };
  },
  emits: ["change"],
  components: { RubricCriteriaExpandButton },
  methods: {
    updateSize() {
      const ref = this.$refs.textarea.$el;
      this.overscroll = ref.scrollHeight > ref.clientHeight;
      if (this.overscroll) {
        ref.style = `--height: ${ref.scrollHeight + 10}px;`;
      }
    },
    onTextUpdate(value) {
      this.$emit("change", value);
      this.$nextTick(() => {
        this.updateSize();
      });
    },
  },
  computed: {
    showButton() {
      return this.expanded || this.overscroll;
    },
  },
  mounted() {
    this.updateSize();
  },
};
</script>

<template>
  <div :class="['textarea-wrapper', { expanded }]">
    <b-textarea
      no-resize
      size="sm"
      :placeholder="`Enter the requirements the student needs to demonstrate to get this grade.\n\nEx: Supports the central claim and reasons with strong facts, thorough details, and accurate citations.`"
      ref="textarea"
      :class="['grade-explanation', 'pale-placeholder', { expanded }]"
      :state="canValidate ? value.length > 0 : null"
      :value="value"
      @update="onTextUpdate"
    />
    <div>
      <RubricCriteriaExpandButton
        v-if="showButton"
        :value="expanded"
        @click="
          expanded = !expanded;
          $nextTick(() => updateSize());
        "
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.textarea-wrapper {
  height: 100%;
  position: relative;
  &.expanded {
    z-index: 1;
  }
}

.grade-explanation {
  height: 100%;
  resize: none;
  transition: box-shadow 0.15s ease-out;
  &.expanded {
    height: var(--height) !important;
    position: absolute;
    box-shadow: rgb(0 0 0 / 12%) 0 0 4px 4px, rgb(0 0 0 / 8%) 0 0 2px 2px;
  }
}
</style>
