<script>
import UploadContentArea from "@/components/dashboard/upload_content_area.vue";

export default {
  name: "UploadSidebar",
  components: { UploadContentArea },
  props: {
    canUpload: Boolean,
  },
  emits: ["upload", "input-files", "file-added", "file-removed"],
  data() {
    return {};
  },
};
</script>

<template>
  <b-sidebar
    id="new-rubric-upload-sidebar"
    class="new-rubric-upload-sidebar"
    width="535px"
    right
    shadow
    lazy
    no-header
    backdrop
    backdrop-variant="transparent"
    sidebar-class="bg-white"
  >
    <template #default="{ hide }">
      <div class="d-flex align-items-center justify-content-between">
        <span class="pl-3 title-text">Edit rubric settings</span>
        <b-button variant="link" size="lg" class="text-dark" @click="hide">
          <b-icon size="md" icon="x" />
        </b-button>
      </div>
      <div class="px-3">
        <UploadContentArea
          class="upload-content-area"
          ref="uploadArea"
          label="Upload rubric"
          required
          :max-number-of-files="1"
          :availableVariants="['files']"
          @input-files="$emit('input-files', $event)"
          @file-added="$emit('file-added', $event)"
          @file-removed="$emit('file-removed', $event)"
        />

        <div class="d-flex">
          <b-button variant="outline-info" @click="hide"> Cancel </b-button>
          <b-button
            :disabled="!canUpload"
            class="ml-3"
            variant="info"
            @click="$refs.uploadArea.upload()"
          >
            Continue
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<style scoped lang="scss">
.upload-content-area::v-deep {
  .uppy-Dashboard {
    height: 325px;
  }
}

.new-rubric-upload-sidebar ::v-deep .b-sidebar {
  height: calc(100vh - var(--navbar-height));
  top: var(--navbar-height);
}

.title-text {
  color: var(--Text-Body, #212529);
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}
</style>
