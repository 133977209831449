<script>
import capitalize from "@/utils/capitalize";

export default {
  name: "ConfirmYearlyUpgradeModal",
  props: {
    newPlan: {
      type: String,
      required: true,
    },
    proceedWithUpgrade: {
      type: Function,
      required: true,
    },
    newPlanPrice: {
      type: String,
      required: true,
    },
  },
  computed: {
    newPlanDisplayName() {
      return capitalize(this.newPlan);
    },
  },
  methods: {
    upgrade() {
      this.proceedWithUpgrade(this.newPlan, true);
      this.$bvModal.hide("confirm_yearly_upgrade_modal");
    },
  },
};
</script>

<template>
  <b-modal
    id="confirm_yearly_upgrade_modal"
    title="Confirm Upgrade"
    size="lg"
    centered
    hide-footer
    no-stacking
  >
    <p>
      This will upgrade you to the {{ newPlanDisplayName }} yearly plan, and you
      will be charged {{ newPlanPrice }}. Your invoice will be discounted by any
      remaining time on your current plan.
    </p>

    <div class="d-flex justify-content-end">
      <b-button variant="outline-info" v-b-modal.confirm_monthly_upgrade_modal>
        Cancel
      </b-button>
      <b-button class="ml-3" variant="primary" @click="upgrade">
        Upgrade
      </b-button>
    </div>
  </b-modal>
</template>
