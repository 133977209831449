<template>
  <b-sidebar
    id="rubric-library-sidebar"
    ref="sidebarRef"
    width="535px"
    right
    shadow
    lazy
    no-header
    backdrop
    backdrop-variant="transparent"
    sidebar-class="bg-white"
    :class="['rubric-sidebar', { 'with-buttons': !autoCommit }]"
  >
    <template #default="{ hide }">
      <div class="pl-3 d-flex align-items-center justify-content-between">
        <div class="title-text">Rubric Library</div>
        <b-button variant="link" size="lg" class="text-dark" @click="hide">
          <b-icon size="md" icon="x" />
        </b-button>
      </div>
      <RubricLibraryView
        @selectRubric="selectRubric"
        :value="autoCommit ? value : rubric"
        :showAddNew="showAddNew"
      />
      <div v-if="!autoCommit" class="d-flex p-3 justify-content-end">
        <b-button variant="outline-info" @click="hide"> Cancel </b-button>
        <b-button
          class="ml-3"
          variant="info"
          @click="
            hide();
            onSave();
          "
        >
          Save changes
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import RubricLibraryView from "@/components/rubrics/rubric_library_view.vue";

export default {
  name: "RubricLibrarySidebar",

  components: {
    RubricLibraryView,
  },

  props: {
    value: Object,
    showAddNew: Boolean,
    autoCommit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rubric: this.value,
    };
  },
  methods: {
    selectRubric(value) {
      if (this.autoCommit) {
        this.$emit("input", value);
      } else {
        this.rubric = value;
      }
    },
    onSave() {
      this.$emit("input", this.rubric);
    },
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  color: var(--Text-Body, #212529);
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}
.with-buttons {
  &::v-deep {
    .b-table-sticky-header {
      max-height: calc(100vh - 280px) !important;
    }
  }
}

.rubric-sidebar {
  &::v-deep {
    .nav-tabs {
      padding: 0 1rem;
    }

    #rubric-library-sidebar {
      height: calc(100vh - var(--navbar-height));
      top: var(--navbar-height);
    }

    .b-table-sticky-header {
      max-height: calc(100vh - 210px);
      margin-bottom: 0;
      padding: 0 16px;
    }

    .name-row {
      max-width: 210px;
      overflow: hidden;
    }
  }
}
</style>
