<template>
  <b-modal
    :id="`export-to-${type}-modal`"
    :title="`Export feedback to ${type.toUpperCase()}`"
    size="lg"
    centered
    @show="onShow"
    @hide="onHide"
  >
    <template #modal-footer="{ close }">
      <div class="d-flex align-items-center justify-content-end">
        <b-button variant="secondary" @click="close">
          {{ success ? "Close" : "Cancel" }}
        </b-button>
        <b-button
          v-if="!success"
          class="ml-2"
          :disabled="exporting || selected.length === 0"
          variant="info"
          @click="onExport"
        >
          Export
        </b-button>
      </div>
    </template>

    <div>
      <div
        v-if="success"
        class="d-flex flex-column align-items-center justify-content-center w-100"
      >
        <b-icon
          icon="check-circle-fill"
          variant="success"
          :style="{ width: '48px', height: '48px' }"
        />
        <h4 class="text-center mt-2">Export complete!</h4>

        <div class="text text-center mb-3">
          Download should start automatically in a few seconds. If not, please
          <b-button class="try-again" variant="link" @click="onExport">
            try again.
          </b-button>
        </div>
      </div>
      <div v-else-if="exporting">
        <div
          class="d-flex flex-column align-items-center justify-content-center w-100"
          style="height: 300px"
        >
          <b-spinner
            class="my-5"
            style="width: 51px; height: 51px"
            variant="info"
          />

          <h4 class="text-center">Exporting to {{ type.toUpperCase() }}...</h4>
        </div>
      </div>
      <div v-else>
        <div class="text mb-3">
          <span v-if="type === 'pdf' && multipleEssaysSelected">
            The essays will be exported as a .zip file.
          </span>
          {{ description }}
        </div>

        <div>
          <export-options
            v-model="selected"
            stacked
            :available-options="availableOptions"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { saveAs } from "file-saver";
import { exportToPdf } from "@/api/requests/export";
import ExportOptions from "./export_options.vue";

export default {
  name: "ExportToPdfModal",

  components: { ExportOptions },

  props: {
    selectedIds: {
      type: Array,
      default: () => [],
    },

    filters: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      type: "pdf",
      selected: [],
      show: false,
      exporting: false,
      success: false,
      availableOptions: ["content", "feedback", "errors", "hideGrades"],
      description: "Select the data that should be included in the PDF file:",
    };
  },

  computed: {
    multipleEssaysSelected() {
      return this.selectedIds.length > 1;
    },
  },

  mounted() {
    this.setDefaultOptions();
  },

  methods: {
    exportTo(payload) {
      return exportToPdf(payload);
    },

    async onExport() {
      this.success = false;
      this.exporting = true;
      try {
        const { data, headers } = await this.exportTo({
          essaysIds: this.selectedIds,
          fields: this.selected,
          filters: this.filters,
        });
        const contentDisposition = headers["content-disposition"];
        const fileName = contentDisposition.match(/filename="(.+)"/)[1];
        saveAs(data, fileName);

        this.exporting = false;
        this.success = true;

        return data;
      } catch (error) {
        this.$showToastError(error?.response?.data?.error);
      }
    },

    setDefaultOptions() {
      this.selected = ["content", "feedback", "errors"];
    },

    async onShow() {
      this.show = true;
    },

    onHide() {
      this.show = false;
      this.success = false;
      this.exporting = false;
      this.setDefaultOptions();
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss">
.btn.try-again {
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: baseline;
}
</style>
