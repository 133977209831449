<template>
  <eg-dropdown ref="dropdown" split class="search-select">
    <template #button-content="{ toggle }">
      <div
        class="button-content d-flex align-items-center justify-content-between"
        @click="clickValue(toggle)"
      >
        <b-form-input
          v-if="!value?.id || showSearch"
          ref="searchInput"
          :value="searchBoxValue"
          class="search-input"
          :placeholder="`Select ${entity}`"
          size="sm"
          @input="search = $event"
          @blur="showSearch = false"
        ></b-form-input>
        <div class="selected-item w-100" v-else>
          <slot v-if="$scopedSlots.item" name="item" v-bind:item="value" />
          <div v-else>{{ value && getTitle(value) }}</div>
        </div>
        <b-icon class="ml-2" icon="chevron-down"></b-icon>
      </div>
    </template>
    <template>
      <b-dropdown-item-button
        v-if="allowDeselect && !noItemsAvailable"
        class="dropdown-item-wrapper"
        @click="deselect"
      >
        <div>Skip selecting {{ entity }}</div>
      </b-dropdown-item-button>
      <component
        v-for="(group, index) in filteredItemGroups"
        :is="groupFunction && group.items.length ? 'b-dropdown-group' : 'div'"
        :key="group.id || index"
        :header="group.title"
      >
        <b-dropdown-item-button
          v-for="item in group.items"
          :key="item.id"
          class="dropdown-item-wrapper"
          :active="value && item.id === value.id"
          @click="selectItem(item)"
        >
          <slot v-if="$scopedSlots.item" name="item" v-bind:item="item" />
          <div v-else>{{ item && getTitle(item) }}</div>
        </b-dropdown-item-button>
      </component>
      <div v-if="noItemsAvailable" class="pl-4">
        <slot v-if="$scopedSlots['no-items']" name="no-items" />
        <div v-else>No {{ entityPluralized }} created</div>
      </div>
      <div v-if="search && filteredItemCount === 0" class="pl-4">
        <slot v-if="$scopedSlots['no-items-found']" name="no-items-found" />
        <div v-else>No {{ entityPluralized }} found</div>
      </div>
      <b-dropdown-divider
        v-if="showCreateEntity"
        class="dropdown-item-wrapper sticky bottom-32"
      />
      <b-dropdown-item-button
        v-if="showCreateEntity"
        class="dropdown-item-wrapper sticky bottom-0 create-item"
        @click="createItem"
      >
        {{ `Create ${entity}` }}
      </b-dropdown-item-button>
    </template>
  </eg-dropdown>
</template>

<script>
import EgDropdown from "@/components/global/eg_dropdown";

export default {
  components: {
    EgDropdown,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    titleKey: {
      type: String,
      default: "title",
    },
    entity: {
      type: String,
      default: "item",
    },
    groupFunction: {
      type: Function,
      default: null,
    },
    allowDeselect: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showSearch: false,
      search: "",
    };
  },

  watch: {
    filteredItemCount(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.$nextTick(() => this.$refs.dropdown.update());
    },
  },

  computed: {
    entityPluralized() {
      switch (this.entity) {
        case "class":
          return "classes";
        case "assignment":
          return "assignments";
        default:
          return this.entity + "s";
      }
    },
    searchBoxValue() {
      if (this.value) {
        return this.getTitle(this.value);
      } else {
        return this.search;
      }
    },
    showCreateEntity() {
      return !!this.$listeners["click:create"];
    },

    itemGroups() {
      return this.groupFunction
        ? this.groupFunction(this.items)
        : [{ id: "all", title: "All", items: this.items }];
    },

    filteredItemGroups() {
      if (!this.search) {
        return this.itemGroups;
      }

      return this.itemGroups
        .map((group) => {
          return {
            ...group,
            items: group.items.filter((item) =>
              this.getTitle(item)
                .toLowerCase()
                .includes(this.search.toLowerCase())
            ),
          };
        })
        .filter((group) => group.items.length > 0);
    },

    filteredItemCount() {
      return this.filteredItemGroups.reduce(
        (acc, curr) => acc + curr.items.length,
        0
      );
    },

    noItemsAvailable() {
      return !this.search && this.items.length === 0;
    },
  },

  methods: {
    getTitle(item) {
      if (!this.titleKey.includes("||")) {
        return item[this.titleKey];
      }

      const titleKeysArr = this.titleKey.split("||");

      let title = "";

      for (let i = 0; i < titleKeysArr.length; i++) {
        title = item[titleKeysArr[i].trim()];

        if (title) {
          break;
        }
      }

      return title;
    },
    selectItem(item) {
      this.$emit("input", item);
      this.search = "";
    },
    deselect() {
      this.$emit("input", {});
      this.search = "";
    },
    clickValue(toggle) {
      this.showSearch = true;
      this.$nextTick(() => {
        toggle({ target: this.$refs.searchInput.$el });
      });
    },

    createItem() {
      this.$emit("click:create");
    },
  },
};
</script>

<style lang="scss" scoped>
.search-input {
  padding: 0;
  border: none;

  &:focus {
    color: #495057;
    box-shadow: none;
    outline: none;
  }
}

.search-input,
.search-input::placeholder {
  font-family: inherit;
  text-transform: none;
  font-weight: 400;
  color: #212529;
  font-size: 1rem;
  line-height: 1.5;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  font-weight: 400;
  color: #212529;
  text-align: left;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-top: 1px;
  padding-bottom: 1px;
  border: 1px solid #ced4da; // TODO: change to CSS variable
}

.selected-item {
  padding: 5px 0;
}

.search-select {
  &::v-deep {
    .dropdown-toggle {
      display: none !important;
    }

    .btn {
      padding: 0;
      border: none;
    }

    .dropdown-item-wrapper.sticky {
      background-color: #fff;
    }

    .dropdown-item.active,
    .dropdown-item:active {
      color: #fff;
      text-decoration: none;
      background-color: rgba(111, 66, 193, 1);
    }
  }

  .sticky {
    position: sticky;
  }

  .bottom-0 {
    bottom: 0;
  }

  .bottom-32 {
    bottom: 32px;
  }
}

.create-item::v-deep button {
  color: #714bd4;
}

.create-item::v-deep button:hover {
  color: #532cb5;
  background-color: inherit;
  text-decoration: underline;
}
</style>
