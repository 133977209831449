<template>
  <div class="essays-view">
    <section class="p-3">
      <template>
        <b-row>
          <b-col cols="12">
            <div class="d-block d-md-flex align-items-center">
              <h1 class="title">Essays</h1>
              <span v-if="queuedEssaysExist">
                <span
                  v-if="nextUpdateTime > 3"
                  class="ml-md-3 light-grey-text text-date"
                >
                  {{ `Next update in ${nextUpdateTime}s` }}
                </span>
                <span v-else class="ml-md-3 light-grey-text text-date">
                  Updating...
                </span>
              </span>
              <b-button class="text-right ml-auto" variant="info" to="/">
                Grade new essay
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-3 mt-2">
          <b-col>
            <usage-counters />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <essays-table
              :essays="essays"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              :tableLoading="tableLoading"
              :initialLoading="initialLoading"
              :total="+count"
              :limit="+limit"
              :filters="{ ...filters, ...defaultFilters }"
              :is-filtered="isFiltered"
              :current-page="+currentPage"
              :selected-all="selectedAll"
              @move="showMoveEssayModal"
              @delete="deleteEssays"
              @getData="fetchEssays"
              @selectedAll="selectedAll = $event"
              @onChangePage="(currentPage = $event) && fetchEssays()"
            />
          </b-col>
        </b-row>
      </template>
    </section>

    <move-essay-modal
      :essayId="essayToMoveId"
      @essayMoved="setMoveSuccess"
      @moveError="setMoveError"
    />
  </div>
</template>

<script>
import UsageCounters from "../components/dashboard/usage_counters.vue";
import EssaysTable from "../components/essay/essays_table.vue";
import MoveEssayModal from "../components/essay/modals/move_essay_modal.vue";
import fetchEssays from "@/mixins/fetchEssays";
import { deleteEssay } from "../api";
import Visibility from "visibilityjs";

export default {
  name: "EssaysView",
  components: {
    UsageCounters,
    EssaysTable,
    MoveEssayModal,
  },

  mixins: [fetchEssays],
  data() {
    return {
      error: "",
      polling: null,
      essayToMoveId: null,
      essayToDeleteId: null,
      reviewedCount: 0,
      gradedCount: 0,
      selectedAll: false,
      nextUpdateTime: null,
    };
  },

  computed: {
    queuedEssaysExist() {
      return this.essays.some((item) => item.status === "Queued");
    },
  },

  methods: {
    async deleteEssays(essaysIds) {
      try {
        await deleteEssay(this.selectedAll ? [] : essaysIds, this.filters);

        this.showDeleteSuccess();

        this.fetchEssays();
      } catch (error) {
        this.error = error.response?.data?.error;
      }
    },
    showMoveEssayModal(essayId) {
      this.essayToMoveId = essayId;
      this.$bvModal.show("move-essay-modal");
    },

    setMoveSuccess() {
      this.fetchEssays();
      this.showMoveSuccess();
    },
    setMoveError(error) {
      this.error = error;
    },
    startPolling() {
      if (!this.queuedEssaysExist) {
        return;
      }

      const POLL_INTERVAL_S = 30;
      this.nextUpdateTime = POLL_INTERVAL_S;

      this.polling = setInterval(() => {
        this.nextUpdateTime = this.nextUpdateTime - 1;
        if (this.nextUpdateTime <= 0) {
          this.nextUpdateTime = POLL_INTERVAL_S;
          this.fetchEssays();
        }
      }, 1000);
    },
    stopPolling() {
      if (this.polling) {
        clearInterval(this.polling);
      }
    },
    showMoveSuccess() {
      this.$bvToast.toast(
        `The essay was moved to the new class successfully.`,
        {
          title: "Essay moved",
          variant: "success",
          autoHideDelay: 3000,
          solid: true,
        }
      );
    },
    showDeleteSuccess() {
      this.$bvToast.toast(`The essay(s) were deleted successfully.`, {
        title: "Essay(s) deleted",
        variant: "success",
        autoHideDelay: 3000,
        solid: true,
      });
    },
  },
  watch: {
    currentPage() {
      // if you start paginating polling stops, since new data
      // will always be on the first page
      this.stopPolling();
      this.startPolling();
    },
  },
  beforeMount() {
    // intial essays fetch to fill data
    this.fetchEssays().then(this.startPolling);

    // Stop polling when the tab is inactive
    // Start polling again when the tab is active.
    Visibility.change(() => {
      if (Visibility.hidden()) {
        this.stopPolling();
      } else {
        this.fetchEssays();
        this.startPolling();
      }
    });
  },
  mounted() {
    // user was re-routed after deleting an essay
    if (this.$route.query.deleted) {
      this.showDeleteSuccess();
    }

    if (this.$route.query.moved) {
      this.showMoveSuccess();
    }
  },
  beforeDestroy() {
    // when component is destroyed, ensure we don't keep polling the
    // endpoint every 30 seconds
    this.stopPolling();
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 32px;
}
</style>
