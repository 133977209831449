<template>
  <div class="additional-collapse">
    <b-button
      :class="visible ? null : 'collapsed'"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="`collapse-${_uid}`"
      @click="visible = !visible"
      variant="link"
    >
      {{ label }}
    </b-button>
    <b-collapse
      :id="`collapse-${_uid}`"
      :visible="visible"
      @input="onInput"
      class="mt-2"
    >
      <slot />
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "More options",
    },
  },
  data() {
    return {
      visible: false,
    };
  },

  watch: {
    value: {
      handler(newVal) {
        this.visible = newVal;
      },
      immediate: true,
    },
  },

  methods: {
    onInput(value) {
      this.visible = value;
      this.$emit("input", value);
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  padding: 0;

  &.btn-link:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
    box-shadow: none;
  }

  &::before {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: -1px;
    vertical-align: 5px;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }

  &.collapsed::before {
    transform: rotate(180deg);
    margin-bottom: -2px;
  }
}
</style>
