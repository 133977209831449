<template>
  <div class="class-card mr-4 mb-3 d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <router-link :to="linkTo" class="box-title">
        <div class="text-truncate mb-0">
          {{ entityName }}
        </div>
      </router-link>
      <img
        v-if="showGoogleLabel"
        src="../../assets/icons/eg-google.svg"
        alt=""
      />
    </div>
    <span class="light-grey-text text-date">
      Created on {{ createdDate }}
    </span>
    <div
      class="bottom-slot light-grey-text d-flex align-items-center justify-content-start mt-auto"
    >
      <slot name="bottom-info" />
    </div>
  </div>
</template>

<script>
import formatDate from "@/utils/formatDate";

export default {
  name: "EntityCard",

  props: {
    entity: {
      type: Object,
      required: true,
    },

    entityType: {
      type: String,
      required: true,
    },

    entityNameKey: {
      type: String,
      default: "name",
    },

    showGoogleLabel: {
      type: Boolean,
      default: false,
    },

    linkToEditView: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    createdDate() {
      return formatDate(this.entity.createdAt);
    },

    entityName() {
      return this.entity?.[this.entityNameKey] || "No name";
    },

    entityId() {
      return this.entity?.id;
    },

    linkTo() {
      if (this.linkToEditView) {
        return `/${this.entityType}/edit/${this.entityId}`;
      }

      return `/${this.entityType}/${this.entityId}`;
    },
  },
};
</script>

<style scoped lang="scss">
.class-card {
  padding: 15px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 87%);
  height: 160px;
}

.box-title {
  width: calc(100% - 20px);
  font-size: 16px;
  font-weight: 400;
}

.bottom-slot,
.text-date {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
</style>
