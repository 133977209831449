<script>
import SettingsSidebar from "@/components/rubrics/newRubric/settings_sidebar.vue";
import Settings from "@/components/rubrics/newRubric/settings.vue";
import SettingsPreviewGrid from "@/components/rubrics/newRubric/settings_preview_grid.vue";
import RubricForm from "@/components/rubrics/rubric_form.vue";
import { generateRubricByAi } from "@/api";
import AiLoadingCard from "@/components/rubrics/newRubric/subComponents/AiLoadingCard.vue";

export default {
  name: "RubricAi",
  components: {
    RubricForm,
    SettingsPreviewGrid,
    Settings,
    SettingsSidebar,
    AiLoadingCard,
  },
  emits: ["createRubric"],
  data() {
    return {
      step: 0,
      specifications: "",
      aiLoading: false,
      rubric: null,
    };
  },
  inject: [
    "updateRubricSettings",
    "clearRubricSettings",
    "newRubricSettings",
    "newRubricData",
  ],
  mounted() {
    this.clearRubricSettings();
  },
  methods: {
    onSaveSettings() {
      if (this.step === 2) {
        this.fetchData();
      }
    },
    async fetchData() {
      this.aiLoading = true;
      try {
        const { data } = await generateRubricByAi({
          category:
            this.newRubricData.categories[
              this.newRubricSettings.selectedCategoryIdx
            ].value,
          gradeLevel:
            this.newRubricData.grades[this.newRubricSettings.selectedGradeIdx]
              .value,
          intensity:
            this.newRubricData.intensities[
              this.newRubricSettings.selectedIntensityIdx
            ].value,
          LanguageId:
            this.newRubricData.languages.value[
              this.newRubricSettings.selectedLanguageIdx
            ].id,
          specifications: this.specifications || undefined,
        });
        this.rubric = data;
      } catch (e) {
        console.error(e);
        this.$showToastError(
          "There was a problem creating the rubric. Please try again, or contact support",
          { title: "Rubric Creation failed" }
        );
        this.step = 1;
      } finally {
        this.aiLoading = false;
      }
    },
  },
};
</script>

<template>
  <b-row>
    <SettingsSidebar grade subject category @onSave="onSaveSettings" />
    <SettingsSidebar
      id="new-rubric-settings-sidebar-2"
      intensity
      language
      @onSave="onSaveSettings"
    />
    <template v-if="step === 0">
      <b-col cols="12">
        <h3>What level are we grading for?</h3>
      </b-col>
      <b-col cols="12">
        <Settings grade subject category />
      </b-col>
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button variant="outline-info" to="/rubrics"> Cancel </b-button>
        <b-button
          class="ml-3 avoid-support-overlap"
          variant="info"
          @click="step = 1"
        >
          Continue
        </b-button>
      </b-col>
    </template>

    <template v-else-if="step === 1">
      <b-col cols="12" class="mb-3">
        <h3>What level are we grading for?</h3>
        <SettingsPreviewGrid grade subject category />
      </b-col>
      <b-col cols="12" class="mb-3">
        <h3>How difficult should the grading be?</h3>
        <Settings intensity language />
      </b-col>
      <b-col cols="10" class="mb-3">
        <h3>What would you like to grade?</h3>
        <p class="text-muted small">
          These instructions will be given to our AI to generate the rubric.
        </p>
        <b-textarea
          v-model="specifications"
          placeholder="Argumentative Grade 10 essay. Include criterias about conciseness, use of evidence, grammar, and sentence structure."
          :rows="5"
        />
      </b-col>
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button variant="outline-info" to="/rubrics"> Cancel </b-button>
        <b-button
          class="ml-3 avoid-support-overlap"
          variant="info"
          @click="
            step = 2;
            fetchData();
          "
        >
          Continue
        </b-button>
      </b-col>
    </template>

    <template v-else-if="step === 2">
      <b-skeleton-wrapper class="flex-grow-1" :loading="aiLoading">
        <template #loading>
          <AiLoadingCard />
        </template>
        <b-col cols="12" class="mb-3">
          <h3>What are we grading for?</h3>
          <SettingsPreviewGrid grade subject category />
        </b-col>
        <b-col cols="12" class="mb-3">
          <h3>How should we grade essays with this rubric?</h3>
          <SettingsPreviewGrid
            sidebar-id="new-rubric-settings-sidebar-2"
            intensity
            language
          />
        </b-col>
        <b-col cols="12" class="mb-3">
          <b>Additional rubric specifications</b>
          <p>{{ specifications || "None" }}</p>
          <b-button variant="outline-info" size="sm" @click="step = 1">
            Edit
          </b-button>
        </b-col>
        <b-col cols="12">
          <rubric-form
            :rubric="rubric"
            editing
            @rubricSubmitted="$emit('createRubric', $event)"
          />
        </b-col>
      </b-skeleton-wrapper>
    </template>
  </b-row>
</template>

<style scoped lang="scss"></style>
