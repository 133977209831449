<script>
import { formatAuthorValue } from "@/utils/format";
import formatDate from "@/utils/formatDate";
import {
  createRubric,
  getSingleRubric,
  getSingleRubricByShareSlug,
} from "@/api";
import Breadcrumbs from "@/components/global/breadcrumbs.vue";
import RubricShareModal from "@/components/rubrics/rubric_share_modal.vue";
import AdvancedRubricBadge from "@/components/rubrics/advanced_rubric_badge.vue";
import AdvancedRubricPreviewTable from "@/components/rubrics/advanced_rubric_preview_table.vue";
import SimpleRubricPreviewTable from "@/components/rubrics/simple_rubric_preview_table.vue";
import { rubricCriteriaToCriteriaTable } from "@/utils/rubricCriteriaToCriteriaTable";
import { pick } from "lodash";

const pageKinds = Object.freeze({
  share: Symbol(),
  preview: Symbol(),
});

export default {
  name: "PreviewRubricView",
  components: {
    SimpleRubricPreviewTable,
    AdvancedRubricPreviewTable,
    AdvancedRubricBadge,
    RubricShareModal,
    Breadcrumbs,
  },
  props: {},
  data() {
    return {
      rubric: null,
      loading: true,
      pageKinds,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Rubrics",
          to: "/rubrics",
        },
        {
          text: this.rubric.name || "View Rubric",
          active: true,
        },
      ];
    },
    loggedIn() {
      return this.$store.state.loggedIn;
    },
    pageKind() {
      return this.$route.meta.isShareView ? pageKinds.share : pageKinds.preview;
    },
    rubricData() {
      return {
        id: this.rubric.id,
        name: this.rubric.name,
        type: this.rubric.type,
        intensity: this.rubric.intensity,
        criterias: this.rubric.criterias,
        LanguageId: this.rubric.language?.id,
        gradeLevel: this.rubric.gradeLevel,
        author: this.rubric.author,
      };
    },
  },
  methods: {
    rubricCriteriaToCriteriaTable,
    formatAuthorValue,
    formatDate,
    async fetchRubric() {
      const fetch =
        this.pageKind === pageKinds.share
          ? () => getSingleRubricByShareSlug(this.$route.params.slug)
          : () => getSingleRubric(this.$route.params.id);

      try {
        const { data } = await fetch();

        this.rubric = data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$showToastError("Error while loading Rubric");
      }
    },
    belongsToCurrentUser(rubric) {
      return rubric.author && rubric.author.id === this.$store.state.user?.id;
    },
    async useRubric() {
      if (this.loggedIn) {
        localStorage.setItem(
          "suggestion_form:selectedRubric",
          JSON.stringify(this.rubric)
        );
        await this.$router.push({ path: "/" });
      } else {
        localStorage.setItem(
          "share:useRubricData",
          JSON.stringify(this.rubricData)
        );
        await this.$router.push("/login");
      }
    },
    async saveAndEdit() {
      const createRubricData = pick(this.rubricData, [
        "name",
        "type",
        "intensity",
        "criterias",
        "LanguageId",
        "gradeLevel",
      ]);
      if (this.loggedIn) {
        const { data } = await createRubric(createRubricData);
        await this.$router.push(`/rubric/edit/${data.id}`);
      } else {
        localStorage.setItem(
          "share:newRubricData",
          JSON.stringify(createRubricData)
        );
        await this.$router.push("/login");
      }
    },
  },
  async mounted() {
    await this.fetchRubric();
  },
};
</script>

<template>
  <b-container fluid>
    <RubricShareModal
      v-if="loggedIn && rubric && belongsToCurrentUser(rubric)"
      :rubric="rubric"
    />
    <section v-if="loading">
      <b-row>
        <b-col>
          <div class="text-center">
            <b-spinner class="mb-3"></b-spinner>
            <p>Processing request...</p>
          </div>
        </b-col>
      </b-row>
    </section>
    <section v-else-if="!!rubric">
      <breadcrumbs
        :items="breadcrumbs"
        v-if="pageKind === pageKinds.preview && loggedIn"
      />
      <b-row class="mb-3">
        <b-col>
          <h1 class="mb-0">{{ rubric.name }}</h1>
          <div class="d-flex flex-gap">
            <AdvancedRubricBadge v-if="rubric.type === 'Advanced'" />
            <span class="light-grey-text small">
              Created on {{ formatDate(rubric.createdAt) }} by
              <b>
                {{ formatAuthorValue(rubric.author, $store) }}
              </b>
            </span>
          </div>
        </b-col>
        <b-col sm="auto">
          <div v-if="pageKind === pageKinds.preview">
            <b-button
              v-if="pageKind === belongsToCurrentUser(rubric)"
              v-b-modal.rubric-share-modal
              variant="info"
            >
              <b-icon scale="0.8" icon="link45deg" />
              Share
            </b-button>
            <b-button class="button-secondary-info ml-2" @click="useRubric">
              Start grading
            </b-button>
            <b-button
              v-if="belongsToCurrentUser(rubric)"
              class="button-secondary-info ml-2"
              :to="{ name: 'edit-rubric', params: { id: rubric.id } }"
            >
              Edit
            </b-button>
          </div>
          <div
            v-if="pageKind === pageKinds.share && !belongsToCurrentUser(rubric)"
          >
            <b-dropdown
              right
              class="ml-4"
              variant="info"
              text="Use in EssayGrader"
            >
              <b-dropdown-item @click="saveAndEdit">
                Save and make edits
              </b-dropdown-item>
              <b-dropdown-item @click="useRubric">
                Grade with immediately
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
      <component
        :is="
          rubric.type === 'Advanced'
            ? 'AdvancedRubricPreviewTable'
            : 'SimpleRubricPreviewTable'
        "
        :criteria="rubricCriteriaToCriteriaTable(rubric.criterias, rubric.type)"
        :isDefault="rubric.type === 'Default'"
      />
    </section>
  </b-container>
</template>

<style scoped></style>
