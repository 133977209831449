<template>
  <div class="selectable-table-wrapper">
    <div v-if="$scopedSlots.selectAll" class="select-all-wrapper">
      <slot
        name="selectAll"
        v-bind:selectedAll="selectedAll"
        v-bind:selectAll="selectAll"
      />
    </div>

    <div class="selectable-table">
      <b-table
        class="table"
        ref="selectableTable"
        hover
        sticky-header
        selectable
        select-mode="multi"
        :items="items"
        :fields="innerFields"
        @row-clicked="handleRowClicked"
      >
        <template #top-row="{ columns }">
          <td :colspan="columns">
            <transition name="fade" mode="out-in" duration="500">
              <b-progress
                v-if="loading"
                animated
                class="w-100"
                height="2px"
                :value="100"
                variant="info"
              />
            </transition>
          </td>
        </template>
        <template #bottom-row="{ columns }">
          <td
            v-if="total === 0 && (initialLoading || limit !== 0)"
            :colspan="columns"
          >
            <div class="d-flex h-100">
              <transition name="fade" mode="out-in">
                <b-spinner
                  v-if="initialLoading"
                  class="spinner-loading m-auto"
                />
                <slot v-else name="empty-state" />
              </transition>
            </div>
          </td>
        </template>
        <template v-if="splitSelect" #head(select)>
          <div class="d-flex align-items-center">
            <b-checkbox
              :checked="selectedAll"
              @change="selectAll"
              variant="info"
              :indeterminate="
                selectedIds.length > 0 &&
                selectedIds.length < itemsToSelect.length
              "
            >
            </b-checkbox>
          </div>
        </template>

        <template v-if="!splitSelect" #cell(select)="{ item, index, value }">
          <div class="d-flex align-items-center">
            <b-checkbox
              v-if="selectable"
              :disabled="getDisabled(item)"
              :checked="selectedIds.includes(item.id)"
              @change="handleSelectItem($event, index)"
            />
            <div>{{ value }}</div>
          </div>
        </template>

        <template v-else #cell(select)="{ item, index }">
          <div class="d-flex align-items-center">
            <b-checkbox
              v-if="selectable"
              :disabled="getDisabled(item)"
              :checked="selectedIds.includes(item.id)"
              @change="handleSelectItem($event, index)"
            />
          </div>
        </template>

        <template #cell(icon)="data">
          <b-icon :icon="data.value.icon" :style="data.value.style" />
        </template>

        <template #cell(action)="data">
          <a v-if="data.value.link" :href="data.value.link" target="_blank">
            {{ data.value.label }}
            <b-icon class="icon-box-arrow-up-right" icon="box-arrow-up-right" />
          </a>
          <b-button
            v-if="data.value.action"
            size="sm"
            v-bind="data.value.props"
            @click="data.value.action(data.item)"
          >
            {{ data.value.label }}
          </b-button>
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template
          v-for="(_, scopedSlotKey) in $scopedSlots"
          #[scopedSlotKey]="data"
        >
          <slot :name="scopedSlotKey" v-bind="data"></slot>
        </template>
      </b-table>
      <div class="d-flex justify-content-center">
        <b-pagination
          v-if="total > limit"
          :value="currentPage"
          :total-rows="total"
          :per-page="limit"
          class="new-pagination"
          @change="$emit('onChangePage', $event)"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },

    fields: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    initialLoading: {
      type: Boolean,
      default: false,
    },

    selectable: {
      type: Boolean,
      default: true,
    },

    splitSelect: {
      type: Boolean,
      default: false,
    },

    currentPage: {
      type: Number,
      default: 1,
    },

    limit: {
      type: Number,
      default: 0,
    },

    total: {
      type: Number,
      default: 0,
    },

    getDisabled: {
      type: Function,
      default: () => false,
    },

    clickAction: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      selectedIds: [],
    };
  },

  computed: {
    itemsToSelect() {
      return this.items.filter((item) => !this.getDisabled(item));
    },

    selectedAll() {
      return this.itemsToSelect.length === 0
        ? false
        : this.selectedIds.length === this.itemsToSelect.length;
    },

    innerFields() {
      if (!this.selectable) return this.fields;

      const [firstItem, ...restFields] = this.fields;

      if (this.splitSelect)
        return [
          { columnClass: "col-select", label: "", key: "select" },
          ...this.fields,
        ];

      return [{ ...firstItem, key: "select" }, ...restFields];
    },
  },

  watch: {
    value: {
      immediate: true,
      handler: function () {
        this.selectedIds = this.value;
        this.clearSelected();

        if (this.value.length > 0) {
          this.value.forEach((id) => {
            const index = this.items.findIndex((item) => item.id === id);
            this.selectRow(index);
          });
        }
      },
    },
  },

  methods: {
    handleSelectItem(value, index) {
      value ? this.selectRowAndEmit(index) : this.unselectRowAndEmit(index);
    },

    handleRowClicked(item, index, event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.getDisabled(item)) return;

      // if a custom action was provided, call it instead.
      if (this.clickAction) {
        this.clickAction(item);
      } else {
        const isSelected = this.selectedIds.includes(item.id);
        this.handleSelectItem(!isSelected, index);
      }
    },

    selectAllRows() {
      this.itemsToSelect.forEach((item) => {
        const index = this.items.findIndex((i) => i.id === item.id);

        if (index > -1) this.selectRow(index);
      });
    },

    selectAllRowsAndEmit() {
      this.selectAllRows();
      this.selectedIds = this.itemsToSelect.map((s) => s.id);
      this.emitSelectedIds();
    },

    clearSelected() {
      this.$refs?.selectableTable?.clearSelected();
    },

    clearSelectedAndEmit() {
      this.clearSelected();
      this.selectedIds = [];
      this.emitSelectedIds();
    },

    emitSelectedIds() {
      this.$emit("input", this.selectedIds);
    },

    selectRow(index) {
      this.$refs?.selectableTable?.selectRow(index);
    },

    selectRowAndEmit(index) {
      this.selectRow(index);
      this.selectedIds.push(this.items[index].id);
      this.emitSelectedIds();
    },

    unselectRow(index) {
      this.$refs?.selectableTable?.unselectRow(index);
    },

    unselectRowAndEmit(index) {
      this.unselectRow(index);
      const itemId = this.items[index].id;
      this.selectedIds = this.selectedIds.filter((id) => id !== itemId);
      this.emitSelectedIds();
    },

    selectAll(value) {
      if (!value) {
        this.clearSelectedAndEmit();
      } else {
        this.selectAllRowsAndEmit();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.select-all-wrapper {
  width: 100%;
  padding: 15px 12px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

/* override bootstrap styles */
.custom-checkbox {
  cursor: pointer;
}

.selectable-table-wrapper.mb-0 .table {
  margin-bottom: 0 !important;
}

.selectable-table {
  &::v-deep {
    .table tr td {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .table.b-table > tbody > .table-active > td {
      background-color: #ffffff;
    }

    .table.b-table tbody tr:last-child {
      border-bottom: 1px solid #dee2e6;
    }

    tr.b-table-top-row {
      z-index: 2;
      position: sticky;
      top: 48px;

      & td {
        padding: 0;
        position: relative;
        border: none;

        & .progress {
          position: absolute;
        }
      }
    }
  }
}

.icon-box-arrow-up-right {
  margin-bottom: 1px;
  margin-left: 1px;
}

.col-select {
  width: 50px;
}

.spinner-loading {
  color: var(--Background-Secondary, #6c757d);
}

.new-pagination {
  margin-top: 1rem;

  &::v-deep {
    .page-link {
      color: #5e37a6;
    }

    .page-item.active .page-link {
      background-color: white;
      border-color: #5e37a6;
      color: #5e37a6;
    }

    .page-link:focus {
      box-shadow: unset;
    }
  }
}
</style>
