<script>
import { getRubrics, getSystemRubrics } from "@/api";
import EmptyState from "../components/global/empty_state.vue";
import RubricShareModal from "@/components/rubrics/rubric_share_modal.vue";
import formatDate from "@/utils/formatDate";
import RubricListItem from "@/components/rubrics/rubric_list_item.vue";

export default {
  name: "RubricsView",
  components: {
    RubricListItem,
    RubricShareModal,
    EmptyState,
  },
  data() {
    return {
      loading: true,
      rubrics: [],
      rubricToShare: null,
      tabIndex: 1,
    };
  },
  computed: {
    canCreateRubric() {
      return this.rubrics.length < 100;
    },
  },
  watch: {
    tabIndex(value) {
      switch (value) {
        case 0:
          this.fetchPlatformRubrics();
          break;
        case 1:
        case 2:
          this.fetchRubrics();
          break;
      }
    },
  },
  methods: {
    formatDate,
    async fetchRubrics() {
      try {
        this.loading = true;
        const { data } = await getRubrics(this.tabIndex === 1);

        this.loading = false;

        this.rubrics = data;
      } catch (error) {
        this.$showToastError("Unable to get rubrics information");
        console.error(error);
      }
    },
    async fetchPlatformRubrics() {
      try {
        this.loading = true;
        const { data } = await getSystemRubrics();

        this.loading = false;

        this.rubrics = data;
      } catch (error) {
        this.$showToastError("Unable to get rubrics information");
        console.error(error);
      }
    },
    shareRubric(rubric) {
      this.rubricToShare = rubric;
      this.$bvModal.show("rubric-share-modal");
    },
  },
  mounted() {
    this.fetchRubrics();
  },
};
</script>

<template>
  <b-container fluid>
    <section>
      <template>
        <RubricShareModal
          @invalidateRubric="fetchRubrics"
          :rubric="rubricToShare"
        />
        <b-row class="mb-3">
          <b-col md="7">
            <h1>Rubrics</h1>
          </b-col>
          <b-col class="text-right create-rubric-column" md="5">
            <b-button
              class="mr-2"
              v-if="canCreateRubric"
              variant="info"
              to="/rubric/new"
            >
              New rubric
            </b-button>
            <b-button class="button-secondary-info" to="/">
              Start grading
            </b-button>
          </b-col>
        </b-row>

        <b-tabs v-model="tabIndex" class="rubrics-tabs">
          <b-tab title="Platform rubrics"></b-tab>
          <b-tab title="My rubrics"></b-tab>
          <b-tab title="Shared with me"></b-tab>
        </b-tabs>

        <div
          v-if="loading"
          class="border rounded-bottom d-flex justify-content-center"
        >
          <b-skeleton-table />
        </div>

        <template v-else>
          <b-list-group class="rubrics-list" v-if="rubrics.length > 0">
            <RubricListItem
              v-for="rubric in rubrics"
              :key="rubric.id"
              :rubric="rubric"
              :hideButtons="tabIndex !== 1"
              @shareRubric="shareRubric"
              :isPlatformRubric="tabIndex === 0"
            />
          </b-list-group>
          <section v-else class="border rounded-bottom rounded-right">
            <empty-state
              v-if="tabIndex === 1"
              title="No rubrics have been created yet."
              description="We don't assume how you want to grade your essays. Create your own rubric to grade essays with."
              button-text="New Rubric"
              button-path="/rubric/new"
            >
              <img
                src="../assets/illustrations/empty-state-rubrics.svg"
                alt="EssayGrader Empty State"
              />
            </empty-state>
            <empty-state
              v-else-if="tabIndex === 2"
              title="No rubrics have been shared with you yet."
              description="Rubrics can be shared with specific users on EssayGrader or with groups of users who have the same email domain. Once they are shared, they will populate here."
              class="empty-shared-rubrics"
            />
          </section>
        </template>
      </template>
    </section>
  </b-container>
</template>

<style scoped>
.rubrics-list {
  border-top-left-radius: 0;
}
.rubrics-tabs ::v-deep .nav-tabs {
  border-bottom: 0;
}
.empty-shared-rubrics {
  display: flex;
  align-items: center;
  height: 500px;
}
</style>
