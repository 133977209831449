<script>
import ToggleButton from "@/components/global/eg_toggle_button.vue";
import EgFormGroup from "@/components/global/eg_form_group.vue";

export const settingProps = {
  grade: Boolean,
  subject: Boolean,
  category: Boolean,
  intensity: Boolean,
  language: Boolean,
};

export default {
  name: "NewRubricSettings",
  components: { EgFormGroup, ToggleButton },
  inject: ["newRubricSettings", "updateRubricSettings", "newRubricData"],
  emits: ["onCancel", "onSave"],
  props: {
    ...settingProps,
    autoCommit: { type: Boolean, default: true },
  },
  data() {
    return {
      gradeIdx: this.newRubricSettings.selectedGradeIdx,
      categoryIdx: this.newRubricSettings.selectedCategoryIdx,
      intensityIdx: this.newRubricSettings.selectedIntensityIdx,
      languageIdx: this.newRubricSettings.selectedLanguageIdx,
    };
  },
  methods: {
    /**
     * @param  property {"grade"|"category"|"intensity"|"language"}
     * @param value {number}
     */
    updateValue(property, value) {
      switch (property) {
        case "language":
          this.languageIdx = value;
          break;
        case "intensity":
          this.intensityIdx = value;
          break;
        case "grade":
          this.gradeIdx = value;
          break;
        case "category":
          this.categoryIdx = value;
          break;
      }
      if (this.autoCommit) {
        this.updateRubricSettings("selectedGradeIdx", this.gradeIdx);
        this.updateRubricSettings("selectedCategoryIdx", this.categoryIdx);
        this.updateRubricSettings("selectedIntensityIdx", this.intensityIdx);
        this.updateRubricSettings("selectedLanguageIdx", this.languageIdx);
      }
    },
  },
};
</script>

<template>
  <div>
    <eg-form-group
      v-if="grade"
      label="Grade"
      description="The grade level this Rubric is for"
      required
    >
      <div class="py-2 flex-gap d-flex flex-wrap">
        <ToggleButton
          class="big-button"
          v-for="(grade, idx) in newRubricData.grades"
          :key="idx"
          :label="grade.label"
          @click="updateValue('grade', idx)"
          :pressed="gradeIdx === idx"
        />
      </div>
    </eg-form-group>

    <eg-form-group
      v-if="category"
      label="Category"
      description="The type of assignment you would like to grade"
    >
      <div class="py-2 flex-gap d-flex flex-wrap">
        <ToggleButton
          class="big-button"
          v-for="(category, idx) in newRubricData.categories"
          :key="idx"
          :label="category.label"
          @click="updateValue('category', idx)"
          :pressed="categoryIdx === idx"
          horizontal
        />
      </div>
    </eg-form-group>

    <eg-form-group
      v-if="intensity"
      label="Grading intensity"
      description="The intensity level that AI grades essays at"
      required
    >
      <div class="py-2 flex-gap d-flex flex-wrap">
        <ToggleButton
          class="big-button"
          v-for="(intensity, idx) in newRubricData.intensities"
          :key="idx"
          :label="intensity.label"
          @click="updateValue('intensity', idx)"
          :pressed="intensityIdx === idx"
          horizontal
        >
          <span :style="{ color: intensity.color, fontSize: '11px' }">
            <b-icon :icon="intensity.icon" />
          </span>
        </ToggleButton>
      </div>
    </eg-form-group>

    <eg-form-group
      v-if="language"
      label="Language"
      description="The language to use for the error report, and to output the feedback in"
      required
    >
      <div class="py-2 flex-gap d-flex flex-wrap">
        <ToggleButton
          :style="{ width: '121px', fontSize: '14px' }"
          v-for="(language, idx) in newRubricData.languages.value"
          :key="language.id"
          :label="language.label"
          @click="updateValue('language', idx)"
          :pressed="languageIdx === idx"
          horizontal
        />
      </div>
    </eg-form-group>

    <div v-if="!autoCommit" class="d-flex justify-content-end mx-2">
      <b-button variant="outline-info" @click="$emit('onCancel')">
        Cancel
      </b-button>
      <b-button
        class="ml-3"
        variant="info"
        @click="
          $emit('onSave', {
            gradeIdx,
            categoryIdx,
            intensityIdx,
            languageIdx,
          })
        "
      >
        Save changes
      </b-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.big-button {
  width: 121px;
  font-size: 14px;
}
</style>
