<script>
import EgSelectableTable from "@/components/global/eg_selectable_table.vue";
import { formatAuthorValue } from "@/utils/format";
import { getRecentRubrics } from "@/api";

export default {
  name: "RecentlyUsedRubrics",
  emits: ["selectRubric"],
  components: {
    EgSelectableTable,
  },
  props: {
    value: Object,
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Recently used",
          class: "name-row",
        },
        {
          key: "type",
          label: "Type",
        },
        {
          key: "author",
          label: "Created by",
          formatter: (value) => formatAuthorValue(value, this.$store),
        },
        {
          key: "action",
          label: "",
          formatter: (value, key, item) => {
            return {
              action: (item) => {
                this.$emit("selectRubric", item);
              },
              label: item.id === this.value?.id ? "Selected" : "Select",
              props: {
                variant: "outline-info",
                class: { "button-secondary-info": item.id === this.value?.id },
              },
            };
          },
        },
      ],
      rubrics: [],
    };
  },
  methods: {
    async fetchRubrics() {
      try {
        const { data } = await getRecentRubrics();
        this.rubrics = data;
      } catch (error) {
        this.$showToastError(error.response?.data?.error);
      }
    },
  },
  mounted() {
    this.fetchRubrics();
  },
};
</script>

<template>
  <div v-if="rubrics.length > 0" class="border-left border-top border-right">
    <EgSelectableTable
      :selectable="false"
      :items="rubrics"
      :fields="fields"
      class="mb-0"
    />
  </div>
</template>

<style scoped lang="scss"></style>
