export default function initCrisp(user) {
  if (!window.$crisp || !user) {
    return;
  }
  window.$crisp.push(["set", "user:email", [user.email]]);
  window.$crisp.push([
    "set",
    "user:nickname",
    [`${user.firstName} ${user.lastName}`],
  ]);

  window.$crisp.push(["set", "session:segments", [[user.plan], true]]);
}
