var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[(_vm.assignments.length > 0 && !_vm.loading)?_c('div',{staticClass:"text-right svg-container"},[_c('img',{attrs:{"src":require("../../assets/illustrations/assignment-illustration.svg"),"alt":""}})]):_vm._e(),(_vm.loading)?_c('section',[_c('b-row',{staticClass:"mt-4"},[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"4"}},[_c('b-skeleton-img',{attrs:{"no-aspect":"","height":"150px"}})],1),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"4"}},[_c('b-skeleton-img',{attrs:{"no-aspect":"","height":"150px"}})],1),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"4"}},[_c('b-skeleton-img',{attrs:{"no-aspect":"","height":"150px"}})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-skeleton-img',{attrs:{"no-aspect":"","height":"150px"}})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-skeleton-img',{attrs:{"no-aspect":"","height":"150px"}})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-skeleton-img',{attrs:{"no-aspect":"","height":"150px"}})],1)],1)],1):_vm._e(),_c('section',[(
        _vm.assignments.length > 0 ||
        _vm.debouncedSearchText !== '' ||
        _vm.selectedClass !== null
      )?[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"lg":"8","md":"12"}},[_c('h1',[_vm._v("Assignments")])]),_c('b-col',{staticClass:"text-right create-rubric-column",attrs:{"lg":"4","md":"12"}},[_c('b-button',{attrs:{"variant":"info","to":"/assignment/new"}},[_vm._v(" New assignment ")])],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('div',{staticClass:"search-input-wrapper ml-3 mr-3"},[_c('b-form-input',{ref:"searchInput",staticClass:"pr-4",attrs:{"size":"sm","type":"text","placeholder":"Filter by assignment title"},on:{"input":function($event){return _vm.changeSearch($event)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),(_vm.searchText)?_c('b-icon',{staticClass:"clear-input",staticStyle:{"color":"#adb5bd"},attrs:{"icon":"x-circle-fill"},on:{"click":_vm.clearSearch}}):_vm._e()],1),_c('class-select',{staticClass:"select-class",attrs:{"size":"sm","value":_vm.selectedClass},on:{"input":_vm.selectClass}})],1),_c('b-row',_vm._l((_vm.assignments),function(assignment){return _c('b-col',{key:assignment.id,attrs:{"lg":"4"}},[_c('assignment-card',{attrs:{"assignment":assignment}})],1)}),1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-pagination',{attrs:{"total-rows":_vm.count,"per-page":_vm.limit},on:{"change":_vm.fetchAssignments},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)]:(
        !_vm.loading &&
        _vm.assignments.length == 0 &&
        _vm.debouncedSearchText === '' &&
        _vm.selectedClass === null
      )?_c('empty-state',{staticClass:"mt-5",attrs:{"title":"No assignments yet","description":"Use assignments to organize essays. Assignments can make grading easier with advanced instructions.","button-text":"New assignment","button-path":"/assignment/new"}},[_c('img',{attrs:{"src":require("../../assets/illustrations/empty-state-assignment.svg"),"alt":"EssayGrader Empty State"}})]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }