export default {
  methods: {
    gtag_report_conversion() {
      if (typeof gtag === "function") {
        // gtag is defined in index.html
        // eslint-disable-next-line no-undef
        gtag("event", "conversion", {
          send_to: "AW-16545029854/Vo7nCLCkvrwZEN69pNE9",
        });
      }
    },

    bing_report_conversion() {
      window.uetq = window.uetq || [];
      window.uetq.push("event", "signup", {});
    },
  },
};
