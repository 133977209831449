<script>
export default {
  inject: ["growthBook"],
  data() {
    return {
      maintenanceMessage: null,
    };
  },
  computed: {
    show() {
      return !!this.maintenanceMessage;
    },
  },

  async mounted() {
    this.maintenanceMessage = this.growthBook?.getFeatureValue(
      "maintenance-message"
    );
  },
};
</script>

<template>
  <b-alert class="mb-0" variant="warning" dismissible :show="show">
    <p class="mb-0 text-center">
      {{ maintenanceMessage }}
    </p>
  </b-alert>
</template>
