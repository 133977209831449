<template>
  <eg-form-group
    description="Vocabulary will be assessed based on the language selected when the essay is graded."
    label="Language"
    label-for="language-select"
    required
  >
    <b-form-select
      id="class-select"
      :value="value"
      :options="languageOptions"
      @change="handleChange"
    ></b-form-select>
  </eg-form-group>
</template>

<script>
import { getLanguages } from "@/api";
import EgFormGroup from "../global/eg_form_group.vue";

const selectedLanguageIdFromStorage =
  localStorage.getItem("selectedLanguageId");

export default {
  components: {
    EgFormGroup,
  },

  props: {
    value: {
      type: Number,
    },
  },

  data() {
    return {
      languageOptions: [],
    };
  },

  created() {
    this.getLanguages();
  },

  methods: {
    handleChange(languageId) {
      this.$emit("input", languageId);
      localStorage.setItem("selectedLanguageId", languageId);
    },

    async getLanguages() {
      try {
        const { data } = await getLanguages();
        this.languageOptions = data.rows.map((language) => ({
          value: language.id,
          text: language.label,
        }));

        if (!selectedLanguageIdFromStorage) {
          this.$emit(
            "input",
            this.languageOptions[0] ? this.languageOptions[0].value : null
          );

          return;
        }

        this.$emit("input", +selectedLanguageIdFromStorage);
      } catch (error) {
        this.$showToastError(error.message);
      }
    },
  },
};
</script>
