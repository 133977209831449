<script>
export default {
  name: "IconBadge",
};
</script>

<template>
  <b-badge pill class="icon-badge">
    <slot name="icon" />
    <slot class="light-grey-text" />
  </b-badge>
</template>

<style scoped lang="scss">
.icon-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.2rem;
  background-color: #f9f9ff;
  font-weight: 400;
}
</style>
