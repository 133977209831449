import api from "@/api/api";

export const getAssignments = ({ page, limit, title, ClassId } = {}) => {
  const params = {};

  if (page !== undefined) {
    params.page = page;
  }

  if (limit !== undefined) {
    params.limit = limit;
  }

  if (title !== undefined) {
    params.title = title;
  }

  if (ClassId !== undefined) {
    params.ClassId = ClassId;
  }

  return api.get("/assignments", { params });
};

export const getAssignment = (id) => {
  return api.get(`/assignments/${id}`);
};

export const createFiles = (files) => {
  return api.post("/assignments/files", { files });
};

export const createAssignment = (payload) => {
  return api.post("/assignments", payload);
};

export const updateAssignment = (payload) => {
  const { id, ...rest } = payload;

  return api.patch(`/assignments/${id}`, rest);
};

export const deleteAssignment = (assignmentId) => {
  return api.delete(`/assignments/${assignmentId}`);
};
