<script>
import confetti from "canvas-confetti";
import { computed } from "vue";

import { createRubric, getLanguages } from "@/api";
import Breadcrumbs from "../components/global/breadcrumbs.vue";
import RubricFromScratch from "@/components/rubrics/newRubric/rubric_from_scratch.vue";
import ToggleButton from "@/components/global/eg_toggle_button.vue";
import RubricAi from "@/components/rubrics/newRubric/rubric_ai.vue";
import RubricFromTemplate from "@/components/rubrics/newRubric/rubric_from_template.vue";
import RubricAiUpload from "@/components/rubrics/newRubric/rubric_ai_upload.vue";
import leaveMixin from "@/mixins/leaveMixin";

const count = 600;
const defaults = {
  origin: { y: 0.8 },
};

function fire(particleRatio, opts) {
  confetti({
    ...defaults,
    ...opts,
    particleCount: Math.floor(count * particleRatio),
  });
}

function fireConfetti() {
  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
}

export default {
  name: "NewRubricView",
  components: {
    RubricFromTemplate,
    RubricAi,
    RubricFromScratch,
    ToggleButton,
    Breadcrumbs,
  },
  mixins: [leaveMixin],
  data() {
    return {
      confirmationOkTitle: "No, keep editing", // override for leaveMixin
      loading: true,
      selectedButton: "template",
      breadcrumbs: [
        { text: "Rubrics", href: "/rubrics" },
        { text: "New", active: true },
      ],
      newRubricSettings: {},
      grades: [
        { label: "Elementary", value: "elementary" },
        { label: "Middle School", value: "middleschool" },
        { label: "High School", value: "highschool" },
        { label: "College", value: "college" },
      ],
      intensities: [
        {
          label: "Easy",
          icon: "triangle-fill",
          color: "#28A745",
          value: "light",
        },
        {
          label: "Normal",
          icon: "square-fill",
          color: "#FFC107",
          value: "moderate",
        },
        {
          label: "Strict",
          icon: "pentagon-fill",
          color: "#DC3545",
          value: "intense",
        },
      ],
      languages: [],
      // won't ship subjects yet
      // subjects: [{ label: "English" }, { label: "Math" }, { label: "Science" }],
      categories: [
        { label: "Essay", value: "essay" },
        { label: "Short Answer", value: "shortAnswer" },
        { label: "Case Study", value: "caseStudy" },
      ],
      selectedButtonToComponent: {
        template: RubricFromTemplate,
        ai: RubricAi,
        scratch: RubricFromScratch,
        upload: RubricAiUpload,
      },
      dirty: false,
    };
  },
  provide() {
    return {
      newRubricSettings: computed(() => this.newRubricSettings),
      clearRubricSettings: () => {
        this.newRubricSettings = {
          selectedGradeIdx: 1,
          selectedLanguageIdx: 0,
          selectedIntensityIdx: 1,
          selectedCategoryIdx: 0,
        };
        this.dirty = false;
      },
      updateRubricSettings: (key, value) => {
        this.$set(this.newRubricSettings, key, value);
        this.dirty = true;
      },
      newRubricData: {
        grades: this.grades,
        intensities: this.intensities,
        languages: computed(() => this.languages),
        // subjects: this.subjects,
        categories: this.categories,
      },
      saveRubricSettings: (settings) => {
        if (typeof settings.gradeIdx === "number") {
          this.$set(
            this.newRubricSettings,
            "selectedGradeIdx",
            settings.gradeIdx
          );
        }
        if (typeof settings.intensityIdx === "number") {
          this.$set(
            this.newRubricSettings,
            "selectedIntensityIdx",
            settings.intensityIdx
          );
        }
        if (typeof settings.languageIdx === "number") {
          this.$set(
            this.newRubricSettings,
            "selectedLanguageIdx",
            settings.languageIdx
          );
        }
        if (typeof settings.categoryIdx === "number") {
          this.$set(
            this.newRubricSettings,
            "selectedCategoryIdx",
            settings.categoryIdx
          );
        }
      },
    };
  },
  methods: {
    fireConfetti,
    async createNewRubric(rubric) {
      const selectedGrade =
        this.grades[this.newRubricSettings.selectedGradeIdx];
      const selectedLanguage =
        this.languages[this.newRubricSettings.selectedLanguageIdx];
      const selectedIntensity =
        this.intensities[this.newRubricSettings.selectedIntensityIdx];

      try {
        const rubricData = {
          ...rubric,
          gradeLevel: selectedGrade.value,
          LanguageId: selectedLanguage.id,
          intensity: selectedIntensity.value,
        };
        const { data } = await createRubric(rubricData);

        this.fireConfetti();

        const redirectTo = this.$route.query.redirectTo || "/rubrics";
        const redirectToHasQuery = redirectTo.includes("?");
        this.$root.$bvToast.toast("The rubric was created successfully.", {
          title: "Success!",
          variant: "success",
          toaster: "b-toaster-top-center",
        });
        this.dirty = false;
        await this.$router.push(
          `${redirectTo}${redirectToHasQuery ? "&" : "?"}rubricId=${data.id}`
        );
      } catch (error) {
        this.$showToastError(`Error while creating rubric`);
        console.error(error);
      }
    },
    async setActiveTab(tab) {
      if (this.selectedButton === tab) {
        return;
      }
      if (this.dirty) {
        const leave = await this.showConfirmationLeaveModal();

        if (leave) {
          this.selectedButton = tab;
        }
      } else {
        this.selectedButton = tab;
      }
    },
    // override for leaveMixin
    getIsDirty() {
      return this.dirty;
    },
  },
  async mounted() {
    try {
      const { data } = await getLanguages();
      this.languages = data.rows;
    } catch (error) {
      this.$showToastError(`Error loading available languages`);
      console.error(error);
    }
  },
};
</script>

<template>
  <b-container fluid>
    <section>
      <breadcrumbs :items="breadcrumbs" />
      <b-row class="mb-3">
        <b-col lg="12">
          <h1>New Rubric</h1>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12" class="create-buttons">
          <ToggleButton
            class="big-button"
            :pressed="selectedButton === 'template'"
            @click="setActiveTab('template')"
            label="Use template"
          >
            <p class="h4 mb-0">
              <b-icon icon="bookmark-check" />
            </p>
          </ToggleButton>

          <ToggleButton
            class="big-button"
            :pressed="selectedButton === 'ai'"
            @click="setActiveTab('ai')"
            label="Build with AI"
          >
            <p class="h4 mb-0">
              <b-icon icon="stars" />
            </p>
          </ToggleButton>

          <ToggleButton
            class="big-button"
            :pressed="selectedButton === 'upload'"
            @click="setActiveTab('upload')"
            label="Upload or import"
          >
            <p class="h4 mb-0">
              <b-icon icon="box-arrow-up" />
            </p>
          </ToggleButton>

          <ToggleButton
            class="big-button"
            :pressed="selectedButton === 'scratch'"
            @click="setActiveTab('scratch')"
            label="Start from scratch"
          >
            <p class="h4 mb-0">
              <b-icon icon="pencil" />
            </p>
          </ToggleButton>
        </b-col>
      </b-row>
      <component
        :is="selectedButtonToComponent[selectedButton]"
        @createRubric="createNewRubric($event)"
      />
    </section>
  </b-container>
</template>

<style scoped lang="scss">
.create-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.big-button {
  width: 188px;
  height: 108px;
}
</style>
