<template>
  <div v-if="loading">
    <b-skeleton width="25%"></b-skeleton>
    <b-skeleton type="input"></b-skeleton>
  </div>
  <eg-form-group v-else label="Class" required>
    <eg-search-select
      entity="class"
      title-key="name||descriptionHeading"
      :items="classes"
      :value="selectedClass"
      @input="selectClass"
    >
      <template #no-items>
        <div>
          No classes were found on your account. You can only import assignments
          from classes created in Google classroom.
        </div>
      </template>
    </eg-search-select>
  </eg-form-group>
</template>

<script>
import { getClassroomClasses } from "@/api";
import EgFormGroup from "@/components/global/eg_form_group.vue";
import EgSearchSelect from "@/components/global/eg_search_select.vue";
import errorHandler from "./errorHandler";

export default {
  components: {
    EgFormGroup,
    EgSearchSelect,
  },

  props: {
    value: {
      type: String,
    },
  },

  inject: ["selectedGoogleAccountId"],

  data() {
    return {
      loading: true,
      selectedClass: null,
      classes: [],
    };
  },
  methods: {
    selectClass(classData) {
      this.selectedClass = classData;
      this.$emit("input", classData.id);
    },

    async init() {
      try {
        this.loading = true;
        const { data } = await getClassroomClasses(
          this.selectedGoogleAccountId
        );
        this.classes = data.classes;

        let selectedClassData = (this.classes || 0)[0];

        if (this.value) {
          const classData = this.classes.find((c) => c.id === this.value);

          if (classData) {
            selectedClassData = classData;
          }
        }

        selectedClassData && this.selectClass(selectedClassData);
        this.loading = false;
        this.$emit("loaded");
      } catch (error) {
        this.loading = false;
        this.$emit("loaded");
        errorHandler({
          vm: this,
          error,
          message:
            "There was a problem fetching classes from your account. Please try again.",
        });
      }
    },
  },

  created() {
    this.init();
  },
};
</script>
