import BasePlugin from "@uppy/core/lib/BasePlugin.js";
import getAllowedMetaFields from "@uppy/utils/lib/getAllowedMetaFields";
import {
  RateLimitedQueue,
  internalRateLimitedQueue,
} from "@uppy/utils/lib/RateLimitedQueue";
import { upload } from "../../api";

const defaultOptions = {
  formData: true,
  fieldName: "file",
  method: "post",
  allowedMetaFields: true,
  bundle: false,
  headers: {},
  timeout: 30 * 1000,
  limit: 5,
  withCredentials: false,
  responseType: "",
};

export default class UppyDashboardUploader extends BasePlugin {
  constructor(uppy, opts = {}) {
    super(uppy, opts);
    this.type = "uploader";
    this.id = "CustomUploader";
    this.uppy = uppy;

    this.opts = {
      ...defaultOptions,
      fieldName: opts.bundle ? "files[]" : "file",
      ...opts,
    };

    // Simultaneous upload limiting is shared across all uploads with this plugin.
    if (internalRateLimitedQueue in this.opts) {
      this.requests = this.opts[internalRateLimitedQueue];
    } else {
      this.requests = new RateLimitedQueue(this.opts.limit);
    }
  }

  getOptions(file) {
    const overrides = this.uppy.getState().xhrUpload;

    const opts = {
      ...this.opts,
      ...(overrides || {}),
      ...(file.xhrUpload || {}),
      headers: {},
    };

    Object.assign(opts.headers, this.opts.headers);

    if (overrides) {
      Object.assign(opts.headers, overrides.headers);
    }

    if (file.xhrUpload) {
      Object.assign(opts.headers, file.xhrUpload.headers);
    }

    return opts;
  }

  getCompanionClientArgs(file) {
    const opts = this.getOptions(file);
    const allowedMetaFields = getAllowedMetaFields(
      opts.allowedMetaFields,
      file.meta
    );
    return {
      ...file.remote?.body,
      protocol: "multipart",
      endpoint: opts.endpoint,
      size: file.data.size,
      fieldname: opts.fieldName,
      metadata: Object.fromEntries(
        allowedMetaFields.map((name) => [name, file.meta[name]])
      ),
      httpMethod: opts.method,
      useFormData: opts.formData,
      headers: opts.headers,
    };
  }

  getCompanionTosClientArgs(file) {
    const opts = this.getOptions(file);

    if (file.tus) {
      // Install file-specific upload overrides.
      Object.assign(opts, file.tus);
    }

    if (typeof opts.headers === "function") {
      opts.headers = opts.headers(file);
    }

    return {
      ...file.remote?.body,
      endpoint: opts.endpoint,
      uploadUrl: opts.uploadUrl,
      protocol: "tus",
      size: file.data.size,
      headers: opts.headers,
      metadata: {
        ...file.meta,
        assembly_url: opts.assembly_url,
        filename: file.name,
        fieldname: "file",
      },
    };
  }

  async getAssemblies() {
    const assembly = this.uppy.getPlugin("Transloadit").assembly.status;
    this.opts.endpoint = assembly.tus_url;
    this.opts.assembly_url = assembly.assembly_url;
  }

  async upload() {
    const files = this.uppy.getFiles();
    const { localFiles, remoteFiles } = files.reduce(
      (acc, file) => {
        if (file.isRemote) {
          acc.remoteFiles.push(file);
        } else {
          acc.localFiles.push(file);
        }

        return acc;
      },
      { localFiles: [], remoteFiles: [] }
    );

    this.opts.isTos && (await this.getAssemblies(remoteFiles));

    await Promise.allSettled([
      ...remoteFiles.map((file) => {
        const getQueue = () => this.requests;
        const controller = new AbortController();

        const removedHandler = (removedFile) => {
          if (removedFile.id === file.id) controller.abort();
        };
        this.uppy.on("file-removed", removedHandler);
        this.uppy.setFileState(file.id, { transloadit: {} });

        const companionClientArgs = this.opts.isTos
          ? this.getCompanionTosClientArgs(file)
          : this.getCompanionClientArgs(file);
        const uploadPromise = this.uppy
          .getRequestClientForFile(file)
          .uploadRemoteFile(file, companionClientArgs, {
            signal: controller.signal,
            getQueue,
          });

        this.requests.wrapSyncFunction(
          () => {
            this.uppy.off("file-removed", removedHandler);
          },
          { priority: -1 }
        )();

        return uploadPromise;
      }),
      this.uploadLocalFile(localFiles),
    ]);
  }

  async uploadLocalFile(files) {
    const formData = new FormData();

    if (files.length === 0) {
      return;
    }

    files.forEach((file) => {
      formData.append("files", file.data);
    });

    try {
      const { data } = await upload(formData);
      const allFiles = this.uppy.getFiles();

      let i = 0;
      for (const file of files) {
        if (allFiles.length === files.length) {
          this.uppy.setFileState(file.id, { transloadit: {} });
        }

        this.uppy.emit("upload-success", file, {
          status: 200,
          body: {
            fileUrl: files.length === 1 ? data[0] : data[i],
          },
          uploadURL: "example.com",
        });
        i++;
      }

      if (allFiles.length === files.length) {
        const assembly = this.uppy.getPlugin("Transloadit").assembly;
        assembly.emit("finished");
        assembly.close();
      }
    } catch (error) {
      for (const file of files) {
        this.uppy.emit("upload-error", file, error);
      }
    }
  }
}
