<script>
import Settings, {
  settingProps,
} from "@/components/rubrics/newRubric/settings.vue";

export default {
  name: "settings_sidebar",
  components: { Settings },
  inject: ["saveRubricSettings"],
  props: {
    ...settingProps,
    id: String,
  },
  emits: ["onSave"],
};
</script>

<template>
  <b-sidebar
    :id="id || 'new-rubric-settings-sidebar'"
    class="new-rubric-settings-sidebar"
    ref="sidebarRef"
    width="535px"
    right
    shadow
    lazy
    no-header
    backdrop
    backdrop-variant="transparent"
    sidebar-class="bg-white"
  >
    <template #default="{ hide }">
      <div class="d-flex align-items-center justify-content-between">
        <span class="pl-3 title-text">Edit rubric settings</span>
        <b-button variant="link" size="lg" class="text-dark" @click="hide">
          <b-icon size="md" icon="x" />
        </b-button>
      </div>
      <div class="pl-3 d-flex align-items-center justify-content-between">
        <Settings
          v-bind="$props"
          :autoCommit="false"
          @onCancel="hide"
          @onSave="
            $emit('onSave', $event);
            saveRubricSettings($event);
            hide();
          "
        />
      </div>
    </template>
  </b-sidebar>
</template>

<style scoped lang="scss">
.new-rubric-settings-sidebar ::v-deep .b-sidebar {
  height: calc(100vh - var(--navbar-height));
  top: var(--navbar-height);
}

.title-text {
  color: var(--Text-Body, #212529);
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
}
</style>
