<template>
  <b-modal
    id="essay-regrade-modal"
    :title="`Re-grade ${essay.name}`"
    ok-title="Re-grade"
    ok-variant="info"
    size="lg"
    centered
    @ok="regradeEssay"
    @show="init"
  >
    <template #modal-footer="{ ok, close }">
      <div>
        <b-button variant="outline" @click="close">Close</b-button>
        <b-button variant="info" :disabled="loadingFeedback" @click="ok">
          <b-spinner v-if="loadingFeedback" small></b-spinner>
          Re-grade
        </b-button>
      </div>
    </template>
    <div>
      <div class="text">
        When you re-grade an essay, you can update the grading difficulty level.
        Each re-grade submission counts towards the total number of essay
        submissions allowed in your plan. You have
        {{ usage?.essaysUsage?.remainder ?? 0 }} submissions remaining.
      </div>
      <eg-form-group label="Advanced rubric" required class="mb-3 mt-3">
        <b-form-select
          v-model="selectedRubric"
          :options="rubricOptions"
        ></b-form-select>
      </eg-form-group>
      <rubric-intensity-radio-group v-model="intensity" />
    </div>
  </b-modal>
</template>

<script>
import { getRubrics, getUsage } from "@/api";
import regradeMixin from "@/mixins/regradeMixin";
import RubricIntensityRadioGroup from "../rubrics/rubric_intensity_radio_group.vue";
import EgFormGroup from "../global/eg_form_group.vue";

export default {
  components: {
    RubricIntensityRadioGroup,
    EgFormGroup,
  },

  mixins: [regradeMixin],

  props: {
    essay: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      intensity: null,
      selectedRubric: null,
      rubrics: [],
      usage: null,
    };
  },

  computed: {
    rubricOptions() {
      return this.rubrics.map((rubric) => {
        return {
          text: rubric.name,
          value: rubric.id,
        };
      });
    },
  },

  methods: {
    async regradeEssay(bvModalEvent) {
      bvModalEvent.preventDefault();

      try {
        this.$emit("startRegrade");
        await this.regradeRequest(this.essay.id, {
          RubricId: this.selectedRubric,
          intensity: this.intensity,
        });
        this.$emit("finishRegrade");
        this.$nextTick(() => {
          this.$bvModal.hide("essay-regrade-modal");
        });
      } catch (error) {
        this.$emit("finishRegrade");
      }
    },

    async init() {
      const promises = [getRubrics(), getUsage()];

      const [{ data: rubrics }, { data: usage }] = await Promise.all(promises);

      this.usage = usage;
      this.intensity = this.essay.intensity;
      this.rubrics = rubrics.filter((rubric) => rubric.type === "Advanced");

      if (this.rubrics.length > 0) {
        const essayRubric = this.rubrics.find(
          (rubric) => rubric.id === this.essay.RubricId
        );
        this.selectedRubric = essayRubric?.id || this.rubrics[0].id;
      }
    },
  },
};
</script>
