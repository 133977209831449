<script>
export default {
  name: "AdvancedRubricPreviewTable",
  props: {
    criteria: {
      type: Array,
      required: true,
    },
  },
  computed: {
    gradesMaxCount() {
      return this.criteria.reduce((a, c) => Math.max(a, c.scales.length), 0);
    },
  },
};
</script>

<template>
  <b-table-simple striped hover responsive bordered outlined>
    <b-thead>
      <b-tr>
        <b-th>Criteria</b-th>
        <b-th>Grade and descriptors</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr v-for="criterion in criteria" :key="criterion.id">
        <b-td class="criteria-name-col">{{ criterion.point }}</b-td>
        <b-td
          class="criteria-description-col"
          :style="{
            'grid-template-columns': `repeat(${gradesMaxCount}, minmax(250px, 1fr))`,
          }"
        >
          <template v-for="(scale, idx) in criterion.scales">
            <span :key="idx.toString() + 'g'">
              <b> {{ scale.scale }} </b>
              <span> {{ scale.title }} </span>
            </span>

            <p :key="idx.toString() + 'd'">
              {{ scale.description }}
            </p>
          </template>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<style scoped>
.criteria-description-col {
  display: inline-grid;
  grid-template-rows: repeat(2, auto);
  grid-auto-flow: column;
  grid-gap: 1rem;
}
.criteria-name-col {
  min-width: 150px;
}
</style>
