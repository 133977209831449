export function rubricCriteriaToCriteriaTable(criterias, rubricType) {
  if (rubricType === "Default" || rubricType === "Simple") {
    return criterias.map((criteria) => ({
      point: criteria.criteriaGrades[0].description,
      scale: criteria.criteriaGrades[0].maxGrade || 0,
    }));
  }

  if (rubricType === "Advanced") {
    return criterias.map((criteria) => ({
      point: criteria.name,
      scale: 0,
      scales: criteria.criteriaGrades.map((scale) => ({
        description: scale.description,
        scale: scale.maxGrade,
        title: scale.title,
      })),
    }));
  }
}
