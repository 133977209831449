<script>
export default {
  name: "SupportView",
};
</script>

<template>
  <b-container>
    <section>
      <b-row>
        <b-col>
          <h1 class="purple-text">Looking for help?</h1>
          <hr />
          <p class="mb-4">
            At EssayGrader, we value your experience and want to ensure that you
            have the best possible time using our product. Whether you have
            questions about how to use our services, want to report a bug, or
            have valuable feedback for us, we are here to help. Thank you for
            choosing EssayGrader!
          </p>
          <h4>Contact Us via Chat</h4>
          <p class="mb-4">
            We're always here to help you make the most of your EssayGrader
            experience. For any questions, immediate concerns, or feedback, you
            can reach us through the chat bubble located at the bottom right of
            your screen. Simply click on it to start a conversation with our
            support team. If our team is away or assisting other customers, you
            will be notified by email when we reply.
          </p>
          <h4>Frequently Asked Questions (FAQ)</h4>
          <p class="mb-4">
            Before reaching out to our support team, we recommend checking our
            comprehensive
            <a href="https://essaygrader.ai/faq" target="_blank">
              Frequently Asked Questions (FAQ)
            </a>
            section. We have compiled a list of common queries and detailed
            answers that might address your concerns right away. Save time and
            find quick solutions by browsing through our FAQ.
          </p>
          <h4>Common Topics Covered in our FAQ:</h4>
          <ul class="mb-4">
            <li>Troubleshooting common issues</li>
            <li>Tips for maximizing the benefits of EssayGrader</li>
            <li>How to use EssayGrader for effectively</li>
          </ul>
          <h4>If You Can't Find the Answer in FAQ</h4>
          <p>
            If you've checked our FAQ and still need assistance, don't hesitate
            to reach out to us. We are more than happy to assist you with any
            queries, concerns, or feedback you may have.
          </p>
          <p class="mb-4">
            Please provide as much detail as possible in your email, including a
            description of the issue you're facing. Our support team will get
            back to you as soon as possible. Email us at:
            <a href="mailto:hello@essaygrader.ai?subject=Support">
              hello@essaygrader.ai
            </a>
          </p>
          <h4>We Appreciate Your Feedback</h4>
          <p>
            At EssayGrader, we value your feedback tremendously. Your insights
            help us enhance our product and provide you with an even better
            experience. If you have suggestions, ideas, or thoughts on how we
            can improve, please feel free to share them with us by filling out
            this
            <a href="https://forms.gle/SZ7n3khm5iSRbMii8" target="_blank">
              short form </a
            >.
          </p>
          <p>
            Thank you for being a part of the EssayGrader community. We look
            forward to assisting you and making your experience with our product
            exceptional!
          </p>
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>
