<template>
  <b-form-input
    class="pr-4"
    size="sm"
    id="essay-search"
    type="text"
    placeholder="Filter by name"
    ref="searchInput"
    v-model="text"
    @input="changeText"
    v-on="$listeners"
  ></b-form-input>
</template>

<script>
import debounce from "@/utils/debounce";

export default {
  name: "InputFilter",
  props: ["value"],

  data() {
    return {
      oldText: "",
      text: "",
    };
  },

  methods: {
    changeText: debounce(function (value = "") {
      if (
        value.length >= 2 ||
        (this.oldText.length >= 2 && value.length === 0)
      ) {
        this.oldText = value;
        this.$emit("input", value);
      }
    }, 500),
  },
};
</script>
