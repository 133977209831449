export function initCanny(user) {
  if (!window.Canny) {
    return;
  }

  window.Canny("identify", {
    appID: "670eb2973a7d58695eb713b6",
    user: {
      // Replace these values with the current user's data
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      id: user.id,
    },
  });
}
