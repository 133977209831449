<template>
  <b-card class="essay-stats-card">
    <div class="light-grey-text">{{ title }}</div>
    <div class="d-flex align-items-center">
      <span class="count d-flex align-items-center mr-2">
        <b-spinner v-if="loading"></b-spinner>
        <div v-else>{{ count }}</div>
      </span>
      <b-icon
        v-if="icon"
        :class="iconClass"
        :icon="icon"
        :scale="iconScale"
      ></b-icon>
    </div>
  </b-card>
</template>

<script>
export default {
  name: "EssayStatsCard",

  props: {
    title: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    iconClass: {
      type: String,
      required: false,
    },
    iconScale: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.count {
  height: 48px;

  &::v-deep {
    .spinner-border {
      color: #6c757d;
      width: 20px;
      height: 20px;
      border: 2px solid currentColor;
      border-right-color: transparent;
    }
  }
}
.bi {
  width: 20px;
  height: 20px;

  &[aria-label="check2 circle"] {
    width: 18px;
    height: 18px;
  }
}
</style>
