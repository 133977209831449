<template>
  <eg-entity-card
    entity-type="assignment"
    :entity="assignment"
    entity-name-key="title"
    :show-google-label="assignment.type === 'googleClassroom'"
    link-to-edit-view
  >
    <template #bottom-info>
      <div v-if="assignment.class" class="mr-3 text-truncate">
        <b-icon icon="folder" class="mr-1"></b-icon>{{ assignment.class.name }}
      </div>
      <div class="flex-shrink-0">
        <b-icon icon="file-text" class="mr-1"></b-icon
        >{{ assignment.essaysCount }} essays
      </div>
    </template>
  </eg-entity-card>
</template>

<script>
import EgEntityCard from "@/components/global/eg_entity_card.vue";

export default {
  name: "AssignmentCard",

  components: {
    EgEntityCard,
  },

  props: {
    assignment: {
      type: Object,
      required: true,
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss"></style>
