export default (active = "", entity) => {
  const breadcrumb = [
    {
      text: "Assignments",
      to: "/assignments",
    },
  ];

  if (entity?.id || entity?.ID) {
    breadcrumb.push({
      text: entity.title || entity.name,
      to: `/assignment/${entity.id || entity.ID}`,
    });
  }

  return [
    ...breadcrumb,
    {
      text: active,
      active: true,
    },
  ];
};
