<template>
  <div class="counters d-flex align-items-center flex-wrap">
    <essay-stats-card
      class="my-2"
      title="Total"
      :count="counters.total"
      :loading="loading"
    />
    <essay-stats-card
      class="ml-3 ml-lg-3 my-2"
      title="Queued"
      :count="counters.queued"
      icon="arrow-repeat"
      iconClass="essay-stats-card-icon-cyan"
      iconScale="1.2"
      :loading="loading"
    />
    <essay-stats-card
      class="ml-3 ml-lg-3 my-2"
      title="Ready for review"
      :count="counters.pending"
      icon="check2-circle"
      iconClass="essay-stats-card-icon-purple"
      iconScale="1.5"
      :loading="loading"
    />
    <essay-stats-card
      class="ml-3 ml-sm-3 my-2"
      title="Reviewed"
      :count="counters.reviewed"
      icon="check2"
      iconClass="essay-stats-card-icon-grey"
      iconScale="1.5"
      :loading="loading"
    />
  </div>
</template>

<script>
import { getCounters } from "@/api";
import EssayStatsCard from "../essay/essay_stats_card.vue";
export default {
  components: {
    EssayStatsCard,
  },
  data() {
    return {
      loading: true,
      counters: {
        total: 0,
        reviewed: 0,
        queued: 0,
        pending: 0,
      },
    };
  },

  created() {
    this.getCounters();
  },

  methods: {
    async getCounters() {
      try {
        this.loading = true;
        const { data: counters } = await getCounters();
        this.counters = counters;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.counters {
  @media (max-width: 768px) {
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;

    .card {
      border: none;
      width: auto;
    }
  }
}
</style>
