<script>
import getBreadcrumbs from "./breadcrumbs";

import Breadcrumbs from "@/components/global/breadcrumbs.vue";
import AssignmentForm from "@/components/assignment/assignment_form.vue";

export default {
  name: "NewAssignmentView",
  components: {
    Breadcrumbs,
    AssignmentForm,
  },

  data() {
    return {
      breadcrumbs: getBreadcrumbs("New assignment"),
    };
  },
};
</script>

<template>
  <b-container>
    <section>
      <breadcrumbs :items="breadcrumbs" />
      <b-row>
        <b-col lg="6">
          <h1>New assignment</h1>
        </b-col>
      </b-row>
      <assignment-form />
    </section>
  </b-container>
</template>
